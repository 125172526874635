import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import qs from 'qs';
import styled from 'styled-components';
import { getCurrentDate,getCurrentTime,getSundayList } from '../component/MyUtils';
import { getUserSession } from '../component/userInfo';


const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
  `

;

function getExten(str){
    let aa = str.split('.');
    return aa[aa.length - 1];
}
function outSize(sz){
   let unit = ["바이트","KB", "MB", "GB"]; 
   for(let i = 0; i < unit.length; i++){
       if(sz < 1024) return sz + unit[i];

       sz = parseInt(sz / 1024);
   }
   return sz + "TB";
}

function outDate(dt){
    return dt.getFullYear() + "-" 
           + (dt.getMonth() + 1) + "-"
           + dt.getDate() + " "
           + dt.getHours() + ":" 
           + dt.getMinutes();

 }



const MembersForm = ()=> {
    // console.log(history);
    // console.log(location);
    // console.log(match);
    const params = useParams(); 
    const loc = useLocation(); 

	const [memberInfo, setMemberInfo] = useState({name:'',userno:'',birth:'',channel_id:'',email:'',gender:'',office:'',sunjang:'',channel:'',photo:'',phone: ''});
	const [memberName, setMemberName] = useState('');
	const [memberBirth, setMemberBirth] = useState('');
	const [memberEmail, setMemberEmail] = useState('');
	const [memberGender, setMemberGender] = useState('');
	const [memberOffice, setMemberOffice] = useState('');
	const [memberSunjang, setMemberSunjang] = useState('');
	const [memberLunar, setMemberLunar] = useState('');
	const [memberPhone, setMemberPhone] = useState('');



    /* 초기값 조회하기 첨부파일 목록 가져오기 */
    useEffect(() => {
        console.log("MembersForm uselocation " , loc);
        setMemberInfo(loc.state.memberInfo);
        setMemberName(loc.state.memberInfo.name);
        setMemberBirth(loc.state.memberInfo.birth);
        setMemberEmail(loc.state.memberInfo.email);
        setMemberGender(loc.state.memberInfo.gender);
        setMemberOffice(loc.state.memberInfo.office);
        setMemberSunjang(loc.state.memberInfo.sunjang);
        setMemberLunar(loc.state.memberInfo.birth_lunar);
        setMemberPhone(loc.state.memberInfo.phone);
        // console.log("MembersForm history" , history);
        // console.log("MembersForm location" , location);
        // console.log("MembersForm match" , match);
    },[]);
    const getYmd = () => {
        let ymd = memberBirth;
        let ret = memberBirth;
        if(ymd && ymd.length == 8) {
            ret = ymd.substring(0,4) + "-" 
                + ymd.substring(4,6) + "-" 
                + ymd.substring(6);
        }
        return ret;
    }
    const setYmd = (dt) => {
        let d = dt.split("-");
        setMemberBirth("" + d[0] + d[1] + d[2]);
    }

    const onSubmit = async(event) => { 
        event.preventDefault();
        let done = true;
        const userSession = getUserSession();

          console.log("done" , done);
          if(done){
            try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'save');
            formData.append('userno', memberInfo.userno);
            formData.append('birth', memberBirth);
            formData.append('birth_lunar', memberLunar);
            formData.append('channel_id', memberInfo.channel_id);
            formData.append('channel', memberInfo.channel);
            formData.append('email', memberEmail);
            formData.append('gender', memberGender);
            formData.append('name', memberName);
            formData.append('office', memberOffice);
            formData.append('sunjang', memberSunjang);
            formData.append('photo', memberInfo.photo);
            formData.append('phone', memberPhone);
            console.log(formData);
     
            fetch('https://work.wekoinonia.org/json/members.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              //headers: new Headers({
                //'Content-Type': 'application/json'
              //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  // window.location.reload();
              }).catch(error => {
                console.error('Error:', error)
              });
              
            } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
            } 
            
        }
      }
	return(
        <section className="container">
        <h1 className="h3 mb-2 text-gray-800">회원 정보 수정</h1>
        { /* xs (<576px).  , sm (≥576px)., md (≥768px), lg(≥992px), xl (≥1200px). and xxl  (≥1400px)*/}
        <MyInput>
            <div className="weekly-input">
                <Form onSubmit={onSubmit}  className="mb-4"> 
                    <Row>
                        <Col  xs={12} sm={12} md={2} ><Image  alt={memberInfo.name} src={memberInfo.photo} thumbnail style={{width: "10rem"}} />
                        </Col> 
                        <Col  xs={12} sm={12} md={10}>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group controlId="name" className="mb-3">
                                        <Form.Label>이름</Form.Label>
                                        <Form.Control type="text" placeholder="이름"   value={memberName}  /*value={JSON.stringify(memberInfo)}*/  onChange={e => setMemberName( e.target.value)} required/>
                                        <Form.Control.Feedback type="invalid">
                                        이름
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group controlId="birth" label="생년월일">
                                        <Form.Label>생년월일</Form.Label>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <Form.Control type="date" placeholder="생년월일" onChange={e => setYmd(e.target.value)}  value={getYmd()||''}  />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                            <div className="form-control">
                                                <Form.Check inline type="radio" name="birth_lunar" label="양력" value="N" onChange={e => setMemberLunar(e.target.value) } checked={memberLunar == 'N'}  />
                                                <Form.Check inline type="radio" name="birth_lunar" label="음력" value="Y" onChange={e => setMemberLunar(e.target.value) } checked={memberLunar == 'Y'}  />
                                            </div>                            
                                            </Col>
                                        </Row>   
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col  md={6}>
                                    <Form.Group  controlId="email" label="이메일" className="mb-3">
                                        <Form.Label>이메일</Form.Label>
                                        <Form.Control type="email" placeholder="이메일"   value={memberEmail}   onChange={e => setMemberEmail(e.target.value)  } required/>
                                        <Form.Control.Feedback type="invalid">
                                        이메일
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                </Col>
                                <Col  md={6}>
                                    <div className="mb-3">
                                        <Form.Label>성별</Form.Label>
                                        <div className="form-control">
                                        <Form.Check inline type="radio" name="gender" label="여" value="F" onChange={e => setMemberGender(e.target.value) } checked={memberGender == 'F'}  />
                                        <Form.Check inline type="radio" name="gender" label="남" value="M" onChange={e => setMemberGender(e.target.value) } checked={memberGender == 'M'}  />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group  controlId="gender" label="직분" className="mb-3">
                                        <Form.Label>직분</Form.Label>
                                        <Form.Control type="text" placeholder="직분"   value={memberOffice||''}   onChange={e => setMemberOffice(e.target.value)  } required/>
                                        <Form.Control.Feedback type="invalid">
                                        직분
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                </Col>
                                <Col md={6}>
                                     <div className="mb-3">
                                        <Form.Label>순장방</Form.Label>
                                        <div className="form-control">
                                            <Form.Check type="checkbox" value='Y'  onChange={e => setMemberSunjang(e.target.checked ? 'Y' :'N')}  checked={memberSunjang == 'Y'} label="사용여부"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col  md={6}>
                                    <Form.Group  controlId="phone" label="전화번호" className="mb-3">
                                        <Form.Label>전화번호</Form.Label>
                                        <Form.Control type="text" placeholder="전화번호"   value={memberPhone}   onChange={e => setMemberPhone(e.target.value)  } required/>
                                        <Form.Control.Feedback type="invalid">
                                        전화번호
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                </Col>
                                <Col  md={6}>
                                    <Form.Group  controlId="channel" label="가입채널" className="mb-3">
                                        <Form.Label>가입채널</Form.Label>
                                        <Form.Control type="text" placeholder="가입 채널" value={memberInfo.channel} readOnly/>
                                        <Form.Control.Feedback type="invalid">
                                        가입 채널
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                </Col>
                            </Row>                            

                        </Col>
                    </Row>
                    <Link to={`/Members`}  className="mx-2" >목록</Link>
                    <Button variant="primary" type="submit" className="w-75">
                            저장
                    </Button>
                    {/* <div>{JSON.stringify(memberInfo)}</div> */}
                </Form> 
  
         </div>
        </MyInput>
		</section>
	)
}

export default MembersForm;