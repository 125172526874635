import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import qs from 'qs';
import styled from 'styled-components';
import { getCurrentDate,getCurrentTime,getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';


const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
  `

;

function getExten(str){
    let aa = str.split('.');
    return aa[aa.length - 1];
}
function outSize(sz){
   let unit = ["바이트","KB", "MB", "GB"]; 
   for(let i = 0; i < unit.length; i++){
       if(sz < 1024) return sz + unit[i];

       sz = parseInt(sz / 1024);
   }
   return sz + "TB";
}

function outDate(dt){
    return dt.getFullYear() + "-" 
           + (dt.getMonth() + 1) + "-"
           + dt.getDate() + " "
           + dt.getHours() + ":" 
           + dt.getMinutes();

 }
/**
 * 서버에 업로드된 이미지 
 * @param iamgeInfo,newsNo
 * @returns 
 */
 function ServerImage ({imageInfo,newsNo}){
    const [image, setImage] = useState(imageInfo);
    const [viewImage, setViewImage] = useState(true);
    const deleteImage = (event) =>{
        if(window.confirm("해당 첨부파일을 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delImg');
            formData.append('newsNo', newsNo);
            formData.append('fileno', image.file_no);
            fetch('https://work.wekoinonia.org/json/news.php', {            
                method: 'POST',
                body: formData,
                mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewImage(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }

        
    }
    
    return (
      <>
        {viewImage && 
        <>
            <Card style={{width: '18rem',paddingRight: '0px',paddingLeft: '0px'}}>
                <Card.Img variant="top" src={"https://work.wekoinonia.org" + image.imgsrc} />
                <Card.Body>
                    <Card.Title>{image.name}</Card.Title>
                    <Card.Text>{outSize(image.size)}</Card.Text>
                    <Button variant="primary"  onClick={deleteImage} >Delete</Button>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">현재 이미지</small>
                </Card.Footer>
            </Card>
       </>
         } 
      </>
    );
  }
  function LocalImage ({imageFile,imageFileUrl,remove,imageRef}){
/*
    const [image, setImage] = useState(imageInfo);
    const [viewImage, setViewImage] = useState(true);
    const deleteImage = (event) =>{
        if(window.confirm("해당 첨부파일을 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delImg');
            formData.append('newsNo', newsNo);
            formData.append('fileno', image.file_no);
            fetch('https://work.wekoinonia.org/json/news.php', {            
                method: 'POST',
                body: formData,
                mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewImage(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }
   }
 */   
    return (
        <>
            <Card  border="warning" style={{width: '18rem',paddingRight: '0px',paddingLeft: '0px'}}>
                <Card.Img variant="top" src={imageFileUrl} />
                <Card.Body>
                    <Card.Title>{imageFile.name}</Card.Title>
                    <Card.Text>{outSize(imageFile.size)}</Card.Text>
                    <Button variant="primary"  onClick={e => { imageRef.current.value = null; remove() }} >Delete</Button>
                </Card.Body>
                <Card.Footer>
                <small className="text-muted">업로드 대상 이미지</small>
                </Card.Footer>
            </Card>                                
        </>
    );
  }

/**
 * 서버상의 첨부파일 
 * @param fileInfo,newsNo
 * @returns 
 */
 function Attatchment ({fileInfo,newsNo}){
    const [file, setFile] = useState(fileInfo);
    const [viewFile, setViewFile] = useState(true);
    const deleteFile = (event) =>{
        if(window.confirm("해당 첨부파일을 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delFile');
            formData.append('newsNo', newsNo);
            formData.append('fileno', file.file_no);
            fetch('https://work.wekoinonia.org/json/news.php', {            
                method: 'POST',
                body: formData,
                //mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewFile(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }

        
    }
    
    return (
      <>
        {viewFile && 
        <>
        <Row className="ms-2 mb-3">
            <Col sm={8} className="fw-bold"> <i className={"bi bi-filetype-" + getExten(file.name) }></i>
                {file.name}
            </Col>            
            <Col sm={4}  className="text-end">
                <small style={{width: '5rem'}}>
                {outSize(file.size)}
                {/* <CloseButton onClick={deleteFile} className="mx-2"  /> */}
                <i className="bi bi-x" onClick={deleteFile} ></i>
                </small>    
            </Col>
        </Row>

        </>
         } 
      </>
    );
  }
/**
 * 첨부대상 로컬파일정보
 * 
 * @param {localfile}
 */
function LocalAttatchment ({localfile,deletelocal}){
    const deleteLocalSelected = (event) =>{
        if(window.confirm(localfile.name + " 첨부파일의 업로드를 취소 하시겠습니까?")){
            deletelocal(localfile);
        }
    };
    return (
        <>
            <div className="ms-2 me-auto">
                <div className="fw-bold"> <i className={"bi bi-filetype-" + getExten(localfile.name) }></i>
                    {localfile.name}
                </div>
                
            </div>
            {outDate(localfile.lastModifiedDate)} 
            <small style={{width: '5rem'}} className="d-flex justify-content-end align-items-center">
                {outSize(localfile.size)}
                <CloseButton onClick={deleteLocalSelected} />
            </small>    
        </>
    );
}

const NewsFuneralForm = ()=> {
    const params = useParams(); 
    const location = useLocation(); 

	const [selectedFileList, setSelectedFileList] = useState([]);
	const [imageFile, setImageFile] = useState(); // local 업로드 대상 
    const [imageFileUrl, setImageFileUrl] = useState();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [serverImageList, setServerImageList] = useState([]);
	const [newsNo, setNewsNo] = useState();
    const [title,setTitle] =useState('');
    const [summary,setSummary] =useState('');
    const [content,setContent] =useState('');
    const [publishDate,setPublishDate] =useState(getCurrentDate());
    const [publishTime,setPublishTime] =useState(getCurrentTime());
    const [contentType,setContentType] =useState('');

    /*callingDate callingTime deceased serviceWeero, serviceIpkwan serviceBalin serviceHakwan place gita */
    const [callingDate,setCallingDate] =useState(''); // 소천일시    
    const [callingTime,setCallingTime] =useState(''); // 소천시간
    const [deceased,setDeceased] =useState(''); // 고인(나이)
    const [serviceWeero,setServiceWeero] =useState(''); // 위로예배
    const [serviceIpkwan,setServiceIpkwan] =useState(''); // 입관예배
    const [serviceBalin,setServiceBalin] =useState(''); // 발인예배
    const [serviceHakwan,setServiceHakwan] =useState(''); // 하관예배
    const [place,setPlace] =useState(''); // 장지
    const [gita,setGita] =useState(''); // 기타 
    
    const [publishPlan,setPublishPlan] =useState(false);

    const [isRegMode, setIsRegMode] = useState(true); //등록으로 표현 
    const [filekey, setFilekey] = useState(((Math.floor(Math.random() * 1000000) + 1000000) + "").substring(1));
    const sundayList = getSundayList(12);
    const [selected, setSelected] = useState();

    const imageRef = useRef()

    /* 초기값 조회하기 첨부파일 목록 가져오기 */
    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
        });
        console.log(query);
        let newsNo  = query.newsNo;
        
        if(newsNo){
            fetchNewsInfo(newsNo)   
        }
        

    },[]);
    
    const fetchNewsInfo = (newsNo) => {
        const userSession = getUserSession();
        const formData = new FormData();
        formData.append('uid', userSession.uid);
        formData.append('action', 'read');
        formData.append('newsNo', newsNo);
        fetch('https://work.wekoinonia.org/json/news.php', {
          method: 'POST',
          body: formData,
          }).then((res) => {
              return res.json(); //Promise 반환
          }).then((json) => {
              console.log(json); // 서버에서 주는 json데이터가 출력 됨
              if(json.code !== "0000"){
                alert(json.msg);
              } else if(json.isExist === "N") { // 미등록으로 신규 등록모드 
                if(selected != newsNo) setSelected(newsNo);
                setUploadedFiles([]);
                setSelectedFileList([]);
                setIsRegMode(true);
              } else  { // 수정모드 
                setNewsNo(json.news_no);
                setIsRegMode(false);
                setTitle(json.title);
                setSummary(json.summary);
                setContent(json.content);
                console.log(json.content);
                let myJSONString = json.content;
                let myEscapedJSONString = myJSONString.replace(/\n/g, "\\n");
                                            // .replace(/\'/g, "\\'")
                                            // .replace(/\"/g, '\\"')
                                            // .replace(/\&/g, "\\&")
                                            // .replace(/\r/g, "\\r")
                                            // .replace(/\t/g, "\\t")
                                            // .replace(/\b/g, "\\b")
                                            // .replace(/\f/g, "\\f");     
                console.log(myEscapedJSONString)          ;
                let jsonObject = JSON.parse(myEscapedJSONString);

                setCallingDate(jsonObject.callingDate);// 소천일시  
                setCallingTime(jsonObject.callingTime);// 소천시간
                setDeceased(reverceSpecChar(jsonObject.deceased)); // 고인(나이)
                setServiceWeero(reverceSpecChar(jsonObject.serviceWeero));// 위로예배
                setServiceIpkwan(reverceSpecChar(jsonObject.serviceIpkwan)); // 입관예배
                setServiceHakwan(reverceSpecChar(jsonObject.serviceBalin));// 발인예배
                setServiceHakwan(reverceSpecChar(jsonObject.serviceHakwan));// 하관예배
                setPlace(reverceSpecChar(jsonObject.place)); // 장지
                setGita(reverceSpecChar(jsonObject.gita));// 기타 

                setPublishDate(json.publish_date);
                setPublishTime(json.publish_time);
                setContentType(json.content_type);
                //console.log("[" + json.title + ","+ json.summary+ ","+json.content+ ","+json.publish_date+ ","+json.publish_time);
                //console.log("[" + title + ","+ summary+ ","+content+ ","+publishDate+ ","+publishTime);
                if(json.filecount == 0) {
                    if(selected != newsNo) setSelected(newsNo);
                    setUploadedFiles([]);
                    setSelectedFileList([]);
                } else {
                    setSelected(newsNo);
                    setUploadedFiles(json.filelist);
                    setSelectedFileList([]);
                }
                setServerImageList(json.imglist)
            }
          }).catch(error => {
            console.error('Error:', error)
          });
       }
    const saveAttachmentPromise = async (file,path,uid,filekey,uploadtype,newsNo)  => {
        const uploadUrl = uploadtype == "img" 
                        ? "https://work.wekoinonia.org/json/imageUpload.php" 
                         : "https://work.wekoinonia.org/json/fileUpload.php"; 
        let result ;

        try { // 여기에서 이미지와 첨부파일을 분리할 것 *******************
            const formData = new FormData();
            formData.append('file', file);
            formData.append('path', path);
            formData.append('fieldName', "file");
            formData.append('uid', uid);
            if(newsNo) formData.append('newsNo', newsNo);
            formData.append('filekey', filekey);
            formData.append('parentTable','church_news');
            formData.append('kind',uploadtype == "img" ? "NEWSIMG" : "NEWSFILE");
            console.log(file.name);
            const response = await fetch(
                uploadUrl,
                        {
                            method: 'POST',
                            body: formData,
                            mode: 'cors',
                        }
                    );
            result = await response.json();
            if(result.code !== "0000"){
                throw new Error(result.msg);
            }
        } catch(error) {
            throw new Error(error);

        }
        return result;              
   }   
   const convertSpecChar = (str) => {
    if(!str) return "";
    str = str.replace(/</g,"&lt;");
    str = str.replace(/>/g,"&gt;");
    str = str.replace(/\"/g,"&quot;");
    str = str.replace(/\'/g,"&#39;");
    str = str.replace(/\n/g,"<br />");
    return str;
   }
   const reverceSpecChar = (str) => {
    if(!str) return "";
    str = str.replaceAll("<br />","\n");
    str = str.replaceAll("&#39;","'");
    str = str.replaceAll("&quot;","\"");
    str = str.replaceAll("&gt;",">");
    str = str.replaceAll("&lt;","<");
    return str;
   }
    const onSubmit = async(event) => { 
        event.preventDefault();
        // image upload check ?????


        let done = true;
        const userSession = getUserSession();
          // 첨부파일 전송 
        for (const selectedFile of selectedFileList) {
            let saveAttachment = saveAttachmentPromise(selectedFile,"news",userSession.uid,filekey,"data",newsNo);
            try {
                let ret = await saveAttachment;
            } catch(e) {
                console.error(e);
                done = false;
                break;
            }
        } // for off
        // 이미지 파일 전송
        try {
            if(imageFile){
                await saveAttachmentPromise(imageFile,"news",userSession.uid,filekey,"img",newsNo);
                removeLocalImageFile();
            }
        } catch(e) {
            console.error(e);
            done = false;
        }


          console.log("done" , done);
          if(done){
            try { 

     
                const jsonContent = {
                    'callingDate' : callingDate // 소천일시  
                    ,'callingTime' : callingTime // 소천시간
                    , 'deceased' : convertSpecChar(deceased)  // 고인(나이)
                    , 'serviceWeero' : convertSpecChar(serviceWeero)  // 위로예배
                    , 'serviceIpkwan' : convertSpecChar(serviceIpkwan)  // 입관예배
                    , 'serviceBalin' : convertSpecChar(serviceBalin)  // 발인예배
                    , 'serviceHakwan' : convertSpecChar(serviceHakwan)  // 하관예배
                    , 'place' : convertSpecChar(place)  // 장지
                    , 'gita' : convertSpecChar(gita)  // 기타                 
                };
                
                let myJSONString = JSON.stringify(jsonContent);
                var myEscapedJSONString = myJSONString.replace(/\\n/g, "\\n")
                                            .replace(/\\'/g, "\\'")
                                            .replace(/\\"/g, '\\"')
                                            .replace(/\\&/g, "\\&")
                                            .replace(/\\r/g, "\\r")
                                            .replace(/\\t/g, "\\t")
                                            .replace(/\\b/g, "\\b")
                                            .replace(/\\f/g, "\\f");
                
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'save');
            formData.append('title', title);
            formData.append('summary', ''); // html및 script 처리 는 ?
            formData.append('content', myEscapedJSONString); // html및 script 처리 는 ?
            formData.append('publishTime', publishDate + " " + publishTime + ":00");// 등록이 잘 되겠는가? -- 초를 추가 해줌
            formData.append('contentType', 'funeral');
            formData.append('filekey', filekey);
            if(newsNo) formData.append('newsNo', newsNo);
            
            fetch('https://work.wekoinonia.org/json/news.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              //headers: new Headers({
                //'Content-Type': 'application/json'
              //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  //fetchNewsInfo(json.news_no); // 재조회
                  window.location.reload();
              }).catch(error => {
                console.error('Error:', error)
              });
            } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
            } 
        }
      }

      const onChange = (e) => {
        //setSelected(e.target.value);
        fetchNewsInfo(e.target.value);
       };


	const addLocalAttachment = (event) => {
        // check upload 여부 
        let newFile = event.target.files[0];
        let newSelected = true;
        selectedFileList.map( (selectedFile) => {
            if(selectedFile.name === newFile.name
                && selectedFile.size === newFile.size
                && selectedFile.lastModified === newFile.lastModified){
                     newSelected = false;
            }
        });
		if(newSelected) setSelectedFileList(selectedFileList.concat(newFile));
        event.target.value = null; // 초기화
	};
    const removeLocalAttachment = (deletedFile) => {
        let newSelectedList = [];

        selectedFileList.map( (selectedFile) => {
            if(selectedFile !== deletedFile){
                newSelectedList.push(selectedFile);
            }

            });
            console.log(newSelectedList);
		setSelectedFileList(newSelectedList);
	};
    const putLocalImageFile = (event) => {
        let changeFile = event.target.files[0];

        setImageFile(changeFile); 
        setImageFileUrl(URL.createObjectURL(changeFile))
        console.log(changeFile);
    }
    const removeLocalImageFile = () => {
        URL.revokeObjectURL(imageFileUrl);
        setImageFileUrl('');
        setImageFile('');
    }

	return(
        <section className="container">
        <h1> 장례소식 {isRegMode ? '등록' : '수정'} </h1>
        <MyInput>
            <div className="weekly-input">
                <Form onSubmit={onSubmit}  className="mb-4">
                    
                    <Form.Group as={Row} className="mb-3" controlId="title">
                        <Form.Label column sm={2}>제목</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="제목" value={title} onChange={e => setTitle(e.target.value)  } required/>
                            <Form.Control.Feedback type="invalid">
                                제목을 입력하시오
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="callingDate">
                        <Form.Label column sm={2}>소천일시</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <Form.Control type="date" placeholder="소천일자" onChange={e => setCallingDate(e.target.value)} value={callingDate} style={{maxWidth: '10rem'}}/>
                                <Form.Control type="time" placeholder="소천시각" onChange={e => setCallingTime(e.target.value)} value={callingTime} style={{maxWidth: '10rem'}}/>
                                <Form.Control type="text" readOnly as={Col}  style={{backgroundColor : '#fff'}}/>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="deceased">
                        <Form.Label column sm={2}>고인(나이)</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="고 ㅇㅇㅇ 성도 (00세)" value={deceased} onChange={e => setDeceased(e.target.value)  } required/>
                            <Form.Control.Feedback type="invalid">
                               고 ㅇㅇㅇ (직분) (나이)를 입력하시오
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="serviceWeero">
                        <Form.Label column sm={2}>위로예배</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="00월 00일(일) 00시 00분( 00시 00분에 [어디]에서 출발)" value={serviceWeero} onChange={e => setServiceWeero(e.target.value)  }/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="serviceIpkwan">
                        <Form.Label column sm={2}>입관예배</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="00월 00일(일) 00시 00분( 00시 00분에 [어디]에서 출발)" value={serviceIpkwan} onChange={e => setServiceIpkwan(e.target.value)  }/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="serviceBalin">
                        <Form.Label column sm={2}>발인예배</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="00월 00일(일) 00시 00분( 00시 00분에 [어디]에서 출발)" value={serviceBalin} onChange={e => setServiceBalin(e.target.value)  }/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="serviceHakwan">
                        <Form.Label column sm={2}>하관예배</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="00월 00일(일) 00시 00분( 00시 00분에 [어디]에서 출발)" value={serviceHakwan} onChange={e => setServiceHakwan(e.target.value)  }/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="place">
                        <Form.Label column sm={2}>빈소</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" placeholder="빈소" value={place} onChange={e => setPlace(e.target.value)  } required/>
                            <Form.Control.Feedback type="invalid">
                               장소를 입력하시오
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="gita">
                        <Form.Label column sm={2}>내용</Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" placeholder="내용"   value={gita} onChange={e => setGita(e.target.value)} required style={{ height:'100px'}}/>
                            <Form.Control.Feedback type="invalid">
                                내용를 입력하시오
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="weddingDate">
                        <Form.Label column sm={2}>발행일시</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <Form.Control type="date" placeholder="발행일" onChange={e => setPublishDate(e.target.value)} value={publishDate} style={{maxWidth: '10rem'}}/>
                                <Form.Control type="time" placeholder="발행시" onChange={e => setPublishTime(e.target.value)} value={publishTime} style={{maxWidth: '10rem'}}/>
                                <Form.Control type="text" readOnly as={Col}  style={{backgroundColor : '#fff'}}/>
                            </InputGroup>
                            <small style={{opacity: '.65',transform: 'scale(.85) translateY(-.5rem) translateX(.15rem)'}} className="mt-2">
                             ※발행시간을 조정하여 홈페이지에 올라가는 시간(특정시간 이후)을 예약할 수 있습니다
                            </small>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="imagefile">
                        <Form.Label column sm={2}>메인이미지</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="file" id="imagefile" placeholder="메인이미지" onChange={putLocalImageFile} accept="image/*" ref={imageRef}/>
                            <Form.Control.Feedback type="invalid">
                                메인이미지를 입력하세요 
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    {(serverImageList.length > 0 || imageFile ) && (
                    <Row>
                       <Col sm={2}></Col>
                       <Col sm={10}>
                           {serverImageList.map((serverImage,index) => (
                                <ServerImage imageInfo={serverImage} newsNo={newsNo} key={index}/>
                            ))}
                            {imageFile  && (
                                <LocalImage imageFile={imageFile} imageFileUrl = {imageFileUrl} remove={removeLocalImageFile} imageRef={imageRef}/>
                            )}
                       </Col>
                    </Row>
                    )}
                    <Form.Group as={Row} className="mb-3" controlId="ufile">
                        <Form.Label column sm={2}>첨부파일</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="file" id='ufile' placeholder="첨부파일"  onChange={addLocalAttachment}    />
                            <Form.Control.Feedback type="invalid">
                                메인이미지를 입력하세요 
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    {selectedFileList.length > 0  && ( // 첨부파일 건수 별로(업로드대상)
                    <Row>
                       <Col sm={2}></Col>
                       <Col sm={10}>
                            <Badge bg="secondary">{selectedFileList.length}</Badge> 건   (업로드 대상파일입니다)
                            <div >
                                <ListGroup  variant="flush"  className="mt-3">
                                {selectedFileList.map( (selectedFile) => (
                                    <ListGroup.Item key={selectedFile.name} className="d-flex justify-content-between align-items-start" style={{padding: '0.5rem 0rem'}}>
                                        <LocalAttatchment localfile={selectedFile} deletelocal={removeLocalAttachment}/>
                                      </ListGroup.Item>
                                ))}
                                </ListGroup>
                            </div>
                       </Col>
                    </Row>
                    )}
                    {uploadedFiles.length > 0  && ( // 첨부파일 건수 별로(서버상 )
                    <Row>
                       <Col sm={2}></Col>
                       <Col sm={10}>
                        {uploadedFiles.map( uploadedFile => 
                                <Attatchment fileInfo={uploadedFile} newsNo={newsNo} key={uploadedFile.file_no}/>
                            )}    
                       </Col>
                    </Row>
                    )}
                   <input type="hidden" name="filekey" value="{filekey}"/>
                    
                    <Link to={`/News`}  className="mx-2" >목록</Link>
                    <Button variant="primary" type="submit" className="w-75">
                            저장
                    </Button>
                </Form> 
 
         </div>
        </MyInput>
		</section>
	)
}

export default NewsFuneralForm;