import React, { useState,useEffect } from 'react'
import Pagination from 'react-bootstrap/Pagination'
/*
paging: {
    page: 1 // 현재페이지
    perBlock: 10 // 블럭당갯수(nav 페이지 갯수)
    perpage: 10 // 페이지당 리스트 갯수
    totalCount: 400 // 총건수
}
*/
/*
<Pagination.First />
<Pagination.Prev />
<Pagination.Item>{1}</Pagination.Item>
<Pagination.Ellipsis />

<Pagination.Item>{10}</Pagination.Item>
<Pagination.Item>{11}</Pagination.Item>
<Pagination.Item active>{12}</Pagination.Item>
<Pagination.Item>{13}</Pagination.Item>
<Pagination.Item disabled>{14}</Pagination.Item>

<Pagination.Ellipsis />
<Pagination.Item>{20}</Pagination.Item>
<Pagination.Next />
<Pagination.Last />
*/
const PagingNav =  ({paging,callback}) => {
    const [pageingInfo,setPageingInfo] = useState({});
    
    const pageOnclick = (event) =>{
        event.preventDefault();
        //console.log(">>>> event.target" , event.target);
        //console.log(">>>> event.currentTarget", event.currentTarget);
        if(event.currentTarget.dataset) {    
            callback(event.currentTarget.dataset.page)
        }
    }
    

    let items = [];
    if(paging && paging.totalCount > 0){
        // 총페이지계산
        let {totalCount,perpage,perBlock,page} = paging;
        if(!perpage) perpage = 10;
        if(!page) page = 1;
    
        const totalPage = ((totalCount % perpage) == 0 ? 0 : 1) +
                        parseInt(totalCount / perpage);
        // 구간계산 
        let blockStart = parseInt((page - 1) / perBlock) * perBlock;
        let blockEnd = blockStart + perBlock;
        if(blockEnd > totalPage) blockEnd = totalPage;

        let firstPage = 1;
        let prevPage = blockStart == 0 ? 1 : blockStart;
        let nextPage = ( blockEnd + 1) > totalPage ? totalPage : blockEnd + 1;
        let lastPage = totalPage;
        
        //console.log("page :: ",page);
        if(blockStart > 1) items.push(<Pagination.First key={'first'} data-page={firstPage}  onClick={pageOnclick}/>);
        if(blockStart > 1) items.push(<Pagination.Prev  key={'prev'}  data-page={prevPage} onClick={pageOnclick}/>);
        for (let number = blockStart + 1; number <= blockEnd; number++) {
            if(number == page){
                items.push(
                    <Pagination.Item key={number} active={number == page}>
                        {number}
                    </Pagination.Item>,
                    );
            } else {
                items.push(
                    <Pagination.Item key={number} active={number == page}  data-page={number} onClick={pageOnclick}>
                        {number}
                    </Pagination.Item>,
                    );
            }
        }
        if(blockEnd < totalPage) items.push(<Pagination.Next key={'next'} data-page={nextPage} onClick={pageOnclick}/>);
        if(blockEnd < totalPage) items.push(<Pagination.Last key={'last'} data-page={lastPage} onClick={pageOnclick}/>);
    }
  
    return (
        <Pagination  className="justify-content-center">{items}</Pagination>
    );
}

export default PagingNav;