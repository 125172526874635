import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import qs from 'qs';
import styled from 'styled-components';
import { getCurrentDate,getCurrentTime,getSundayList } from '../component/MyUtils';
import { getUserSession } from '../component/userInfo';


const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
  `

;

function getExten(str){
    let aa = str.split('.');
    return aa[aa.length - 1];
}
function outSize(sz){
   let unit = ["바이트","KB", "MB", "GB"]; 
   for(let i = 0; i < unit.length; i++){
       if(sz < 1024) return sz + unit[i];

       sz = parseInt(sz / 1024);
   }
   return sz + "TB";
}

function outDate(dt){
    return dt.getFullYear() + "-" 
           + (dt.getMonth() + 1) + "-"
           + dt.getDate() + " "
           + dt.getHours() + ":" 
           + dt.getMinutes();

 }
/**
 * 서버에 업로드된 이미지 
 * @param imagePath
 * @returns 
 */
 function ServerImageViewer ({imagePath,device}){
    const [imgPath, setImgPath] = useState(imagePath);
    const [viewImage, setViewImage] = useState(true);
    const [w, setW] = useState('pc' == device ? '' : '12rem');
    const [h, setH] = useState('pc' == device ? '12rem' : '');
    const [originalWidth , setOriginalWidth] = useState();
    const [originalHeight , setOriginalHeight] = useState();
    //naturalWidth, naturalHeight
    // img onload 설정
    const onLoad = (e) => {
        console.log(e.target); 
        //console.log("naturalWidth" , e.target.naturalWidth);
         //console.log("naturalHeight" , e.target.naturalHeight);
         setOriginalWidth(e.target.naturalWidth);
         setOriginalHeight(e.target.naturalHeight);
    }
    return (
      <>
        {viewImage && 
        <>
            <Card style={{ paddingRight: '0px',paddingLeft: '0px' , width:'50%'}}>
                <Card.Img variant="top" src={"https://work.wekoinonia.org" + imgPath} style={{width: w, height: h}}  onLoad={onLoad} />
                <Card.Body>
                    <Card.Title>{imgPath}</Card.Title>
                    <Card.Text>{/* outSize(image.size) */}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">현재 이미지({originalWidth}px  X {originalHeight}px)</small>
                </Card.Footer>
            </Card>
       </>
         } 
      </>
    );
  }
  function LocalImage ({imageFile,imageFileUrl,remove,imageRef}){
/*
    const [image, setImage] = useState(imageInfo);
    const [viewImage, setViewImage] = useState(true);
    const deleteImage = (event) =>{
        if(window.confirm("해당 첨부파일을 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delImg');
            formData.append('swiperCategoryNo', swiperCategoryNo);
            formData.append('fileno', image.file_no);
            fetch('https://work.wekoinonia.org/json/swiper.php', {            
                method: 'POST',
                body: formData,
                mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewImage(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }
   }
 */   
    return (
        <>
            <Card  border="warning" style={{width: '18rem',paddingRight: '0px',paddingLeft: '0px'}}>
                <Card.Img variant="top" src={imageFileUrl} />
                <Card.Body>
                    <Card.Title>{imageFile.name}</Card.Title>
                    <Card.Text>{outSize(imageFile.size)}</Card.Text>
                    <Button variant="primary"  onClick={e => { imageRef.current.value = null; remove() }} >Delete</Button>
                </Card.Body>
                <Card.Footer>
                <small className="text-muted">업로드 대상 이미지</small>
                </Card.Footer>
            </Card>                                
        </>
    );
  }

/**
 * 서버상의 첨부파일 
 * @param fileInfo,swiperCategoryNo
 * @returns 
 */
 function Attatchment ({fileInfo,swiperCategoryNo}){
    const [file, setFile] = useState(fileInfo);
    const [viewFile, setViewFile] = useState(true);
    const deleteFile = (event) =>{
        if(window.confirm("해당 첨부파일을 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delFile');
            formData.append('swiperCategoryNo', swiperCategoryNo);
            formData.append('fileno', file.file_no);
            fetch('https://work.wekoinonia.org/json/swiper.php', {            
                method: 'POST',
                body: formData,
                mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewFile(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }

        
    }
    
    return (
      <>
        {viewFile && 
        <>
        <Row className="ms-2 mb-3">
            <Col sm={8} className="fw-bold"> <i className={"bi bi-filetype-" + getExten(file.name) }></i>
                {file.name}
            </Col>            
            <Col sm={4}  className="text-end">
                <small style={{width: '5rem'}}>
                {outSize(file.size)}
                {/* <CloseButton onClick={deleteFile} className="mx-2"  /> */}
                <i className="bi bi-x" onClick={deleteFile} ></i>
                </small>    
            </Col>
        </Row>

        </>
         } 
      </>
    );
  }
/**
 * 첨부대상 로컬파일정보
 * 
 * @param {localfile}
 */
function LocalAttatchment ({localfile,deletelocal}){
    const deleteLocalSelected = (event) =>{
        if(window.confirm(localfile.name + " 첨부파일의 업로드를 취소 하시겠습니까?")){
            deletelocal(localfile);
        }
    };
    return (
        <>
            <div className="ms-2 me-auto">
                <div className="fw-bold"> <i className={"bi bi-filetype-" + getExten(localfile.name) }></i>
                    {localfile.name}
                </div>
                
            </div>
            {outDate(localfile.lastModifiedDate)} 
            <small style={{width: '5rem'}} className="d-flex justify-content-end align-items-center">
                {outSize(localfile.size)}
                <CloseButton onClick={deleteLocalSelected} />
            </small>    
        </>
    );
}

const MainSwiperForm = ()=> {
    const params = useParams(); 
    const location = useLocation(); 

	const [selectedFileList, setSelectedFileList] = useState([]);
	const [pcImageFile, setPcImageFile] = useState(); // local 업로드 대상 
	const [moImageFile, setMoImageFile] = useState(); // local 업로드 대상 
    const [pcImageFileUrl, setPcImageFileUrl] = useState();
    const [moImageFileUrl, setMoImageFileUrl] = useState();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [serverImageList, setServerImageList] = useState([]);
	const [swiperCategoryNo, setSwiperCategoryNo] = useState();
    const [categoryName,setCategoryName] =useState('');
    const [title,setTitle] =useState('');
    const [link,setLink] =useState('');
    const [startPubDate,setStartPubDate] =useState(getCurrentDate());
    const [startPubTime,setStartPubTime] =useState(getCurrentTime());
    const [endPubDate,setEndPubDate] =useState(getCurrentDate());
    const [endPubTime,setEndPubTime] =useState(getCurrentTime());

    const [pcImgPath,setPcImgPath] =useState('');
    const [moImgPath,setMoImgPath] =useState('');

    const [publishPlan,setPublishPlan] =useState(false);

    const [isRegMode, setIsRegMode] = useState(true); //등록으로 표현 
    const [filekey, setFilekey] = useState(((Math.floor(Math.random() * 1000000) + 1000000) + "").substring(1));
    const sundayList = getSundayList(12);
    const [selected, setSelected] = useState();

    const pcImageRef = useRef()
    const moImageRef = useRef()

    /* 초기값 조회하기 첨부파일 목록 가져오기 */
    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
        });
        console.log(query);
        let swiperCategoryNo  = query.swiperCategoryNo;
        
        if(swiperCategoryNo){
            fetchBannerInfo(swiperCategoryNo)   
        }
        

    },[]);
    
    const fetchBannerInfo = (swiperCategoryNo) => {
        const userSession = getUserSession();
        const formData = new FormData();
        formData.append('uid', userSession.uid);
        formData.append('action', 'read');
        formData.append('swiperCategoryNo', swiperCategoryNo);
        fetch('https://work.wekoinonia.org/json/swiper.php', {
          method: 'POST',
          body: formData,
          }).then((res) => {
              return res.json(); //Promise 반환
          }).then((json) => {
              console.log(json); // 서버에서 주는 json데이터가 출력 됨
              if(json.code !== "0000"){
                alert(json.msg);
              } else if(json.isExist === "N") { // 미등록으로 신규 등록모드 
                if(selected != swiperCategoryNo) setSelected(swiperCategoryNo);
                setUploadedFiles([]);
                setSelectedFileList([]);
                setIsRegMode(true);
              } else  { // 수정모드 
                setSwiperCategoryNo(json.swiper_category_no);
                setIsRegMode(false);
                setCategoryName(json.category_name);
                setTitle(json.title);
                setLink(json.link); 
                setStartPubDate(json.start_pub_date);
                setStartPubTime(json.start_pub_time);
                setEndPubDate(json.end_pub_date);
                setEndPubTime(json.end_pub_time);
                //console.log("[" + json.category_name + ","+ json.title+ ","+json.content+ ","+json.publish_date+ ","+json.publish_time);
                //console.log("[" + categoryName + ","+ title+ ","+content+ ","+publishDate+ ","+publishTime);
                setPcImgPath(json.pc_img_path);
                setMoImgPath(json.mo_img_path);
                if(json.filecount == 0) {
                    if(selected != swiperCategoryNo) setSelected(swiperCategoryNo);
                    setUploadedFiles([]);
                    setSelectedFileList([]);
                } else {
                    setSelected(swiperCategoryNo);
                    setUploadedFiles(json.filelist);
                    setSelectedFileList([]);
                }
                setServerImageList(json.imglist)
            }
          }).catch(error => {
            console.error('Error:', error)
          });
       }
    const saveAttachmentPromise = async (file,path,uid,filekey,uploadtype,swiperCategoryNo)  => {
        const uploadUrl =  "https://work.wekoinonia.org/json/imageUpload.php" ; 
        let result ;

        try { // 여기에서 이미지와 첨부파일을 분리할 것 *******************
            const formData = new FormData();
            formData.append('file', file);
            formData.append('path', path);
            formData.append('fieldName', "file");
            formData.append('uid', uid);
            if(swiperCategoryNo) formData.append('swiperCategoryNo', swiperCategoryNo);
            formData.append('filekey', filekey);
            formData.append('parentTable','main_swiper');
            formData.append('kind',uploadtype == "pc_img" ? "SWIPERPC" : "SWIPERMO");
            console.log(file.name);
            const response = await fetch(
                uploadUrl,
                        {
                            method: 'POST',
                            body: formData,
                            mode: 'cors',
                        }
                    );
            result = await response.json();
            if(result.code !== "0000"){
                throw new Error(result.msg);
            }
        } catch(error) {
            throw new Error(error);

        }
        return result;              
   }   

    const onSubmit = async(event) => { 
        event.preventDefault();
        // image upload check ?????


        let done = true;
        const userSession = getUserSession();

        // 이미지 파일 전송
        try {
            if(pcImageFile){
                await saveAttachmentPromise(pcImageFile,"main_swiper",userSession.uid,filekey,"pc_img",swiperCategoryNo);
                removeLocalPcImageFile();
            }
        } catch(e) {
            console.error(e);
            done = false;
        }
        // 이미지 파일 전송
        try {
            if(moImageFile){
                await saveAttachmentPromise(moImageFile,"main_swiper",userSession.uid,filekey,"mo_img",swiperCategoryNo);
                removeLocalMoImageFile();
            }
        } catch(e) {
            console.error(e);
            done = false;
        }


          console.log("done" , done);
          if(done){
            try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'save');
            formData.append('categoryName', categoryName);
            formData.append('title', title); // html및 script 처리 는 ?
            formData.append('filekey', filekey);

            formData.append('link', link);

            formData.append('startPubDate',  startPubDate + " " + startPubTime + ":00"  );
            formData.append('endPubDate', endPubDate + " " + endPubTime + ":00"  );

            formData.append('pcImgPath', pcImgPath);
            formData.append('moImgPath', moImgPath);

            if(swiperCategoryNo) formData.append('swiperCategoryNo', swiperCategoryNo);
            
            fetch('https://work.wekoinonia.org/json/swiper.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              //headers: new Headers({
                //'Content-Type': 'application/json'
              //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  //fetchNewsInfo(json.swiper_category_no); // 재조회
                  window.location.reload();
              }).catch(error => {
                console.error('Error:', error)
              });
            } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
            } 
        }
      }

      const onChange = (e) => {
        //setSelected(e.target.value);
        fetchBannerInfo(e.target.value);
       };


	const addLocalAttachment = (event) => {
        // check upload 여부 
        let newFile = event.target.files[0];
        let newSelected = true;
        selectedFileList.map( (selectedFile) => {
            if(selectedFile.name === newFile.name
                && selectedFile.size === newFile.size
                && selectedFile.lastModified === newFile.lastModified){
                     newSelected = false;
            }
        });
		if(newSelected) setSelectedFileList(selectedFileList.concat(newFile));
        event.target.value = null; // 초기화
	};
    const removeLocalAttachment = (deletedFile) => {
        let newSelectedList = [];

        selectedFileList.map( (selectedFile) => {
            if(selectedFile !== deletedFile){
                newSelectedList.push(selectedFile);
            }

            });
            console.log(newSelectedList);
		setSelectedFileList(newSelectedList);
	};
    const putLocalPcImageFile = (event) => {
        let changeFile = event.target.files[0];

        setPcImageFile(changeFile); 
        setPcImageFileUrl(URL.createObjectURL(changeFile))
        console.log(changeFile);
    }
    const removeLocalPcImageFile = () => {
        URL.revokeObjectURL(pcImageFileUrl);
        setPcImageFileUrl('');
        setPcImageFile('');
    }
    const putLocalMoImageFile = (event) => {
        let changeFile = event.target.files[0];

        setMoImageFile(changeFile); 
        setMoImageFileUrl(URL.createObjectURL(changeFile))
        console.log(changeFile);
    }
    const removeLocalMoImageFile = () => {
        URL.revokeObjectURL(moImageFileUrl);
        setMoImageFileUrl('');
        setMoImageFile('');
    }

	return(
        <section className="container">
        <h1> 메인 SWIPER {isRegMode ? '등록' : '수정'}</h1>
        <MyInput>
            <div className="weekly-input">
                <Form onSubmit={onSubmit}  className="mb-4">
                    <FloatingLabel controlId="categoryName" label="분류제목" className="mb-3">
                        <Form.Control type="text" placeholder="분류제목" value={categoryName} onChange={e => setCategoryName(e.target.value)  } required/>
                        <Form.Control.Feedback type="invalid">
                            분류제목을 입력하시오
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="title" label="제목" className="mb-3">
                        <Form.Control type="text" placeholder="제목" value={title}   onChange={e => setTitle(e.target.value)} required/>
                        <Form.Control.Feedback type="invalid">
                            제목을 입력하시오
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="content" label="링크" className="mb-3">
                        <Form.Control type="text" placeholder="링크"   value={link} onChange={e => setLink(e.target.value)}  />
                        <Form.Control.Feedback type="invalid">
                            링크를 입력하시오 
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <InputGroup className="content-border mb-3">
                        <FloatingLabel controlId="publish_plan" label="공개시작일자">
                            <Form.Control type="date" placeholder="시작일" onChange={e => setStartPubDate(e.target.value)} value={startPubDate} style={{width: 'auto', border: '0px solid #ffffff'}}/>
                        </FloatingLabel>
                        <FloatingLabel controlId="publish_plan" label="시간">
                            <Form.Control type="time" placeholder="시작일시" onChange={e => setStartPubTime(e.target.value)} value={startPubTime} style={{width: 'auto', border: '0px solid #ffffff'}}/>
                        </FloatingLabel>
                    </InputGroup>
                    <InputGroup className="content-border mb-3">
 
                        <FloatingLabel controlId="publish_plan" label="공개종료일자">
                            <Form.Control type="date" placeholder="종료일" onChange={e => setEndPubDate(e.target.value)} value={endPubDate} style={{width: 'auto', border: '0px solid #ffffff'}}/>
                        </FloatingLabel>
                        <FloatingLabel controlId="publish_plan" label="시간">
                            <Form.Control type="time" placeholder="종료일시" onChange={e => setEndPubTime(e.target.value)} value={endPubTime} style={{width: 'auto', border: '0px solid #ffffff'}}/>
                        </FloatingLabel>

                        </InputGroup>
                    <small style={{opacity: '.65',transform: 'scale(.85) translateY(-.5rem) translateX(.15rem)'}} className="mt-2">
                             ※발행시간을 조정하여 홈페이지에 올라가는 시간(특정시간 이후)을 예약할 수 있습니다
                    </small>
                    <Row className="mb-3 content-type" style={{marginRight : '0px',marginLeft: '0px'}}>
                    <InputGroup className="content-type" style={{border: '0px solid #fff',padding: '0rem 0rem 1rem 0rem'}}>
                       <label  style={{padding: '0rem 0rem 1rem 0rem'}}>PC이미지 ex) 1920px(width) X 650px(height)</label>
                       <Form.Control type="file" id="pcImgFile" placeholder="PC이미지" className="mt-3" onChange={putLocalPcImageFile} accept="image/*" ref={pcImageRef}/>
                       <Form.Control.Feedback type="invalid">
                             PC이미지를 입력하세요 
                        </Form.Control.Feedback>
                    </InputGroup>

                            {/* {serverImageList && serverImageList.map((serverImage,index) => (
                                <ServerImage imageInfo={serverImage} swiperCategoryNo={swiperCategoryNo} key={index}/>
                            ))} */}
                            {/* {pcImgPath && (<img src={'https://work.wekoinonia.org/' + pcImgPath} style={{width: 'auto',height: '10rem'}}/>)} */}
                            {pcImgPath && (<ServerImageViewer imagePath={pcImgPath} device="pc" />)}

                            {pcImageFile  && (
                                <LocalImage imageFile={pcImageFile} imageFileUrl = {pcImageFileUrl} remove={removeLocalPcImageFile} imageRef={pcImageRef}/>
                            )}
                    </Row>
                    <Row className="mb-3 content-type" style={{marginRight : '0px',marginLeft: '0px'}}>
                    <InputGroup className="content-type" style={{border: '0px solid #fff',padding: '0rem 0rem 1rem 0rem'}}>
                       <label  style={{padding: '0rem 0rem 1rem 0rem'}}>모바일 이미지 ex) 790px(width) X 1037px(height)</label>
                       <Form.Control type="file" id="pcImgFile" placeholder="모바일 이미지" className="mt-3" onChange={putLocalMoImageFile} accept="image/*" ref={moImageRef}/>
                       <Form.Control.Feedback type="invalid">
                             모바일 이미지를 입력하세요 
                        </Form.Control.Feedback>
                    </InputGroup>
                            {/* {moImgPath && (<img src={'https://work.wekoinonia.org/' + moImgPath} style={{width: '10rem',height: 'auto'}}/>)} */}
                            {moImgPath && (<ServerImageViewer imagePath={moImgPath}  device="mo" />)}
                            
                            {moImageFile  && (
                                <LocalImage imageFile={moImageFile} imageFileUrl = {moImageFileUrl} remove={removeLocalMoImageFile} imageRef={moImageRef}/>
                            )}
                    </Row>
                    <input type="hidden" name="filekey" value="{filekey}"/>
                    
                    <Link to={`/MainSwiperList`}  className="mx-2" >목록</Link>
                    <Button variant="primary" type="submit" className="w-75">
                            저장
                    </Button>
                </Form> 
 
         </div>
        </MyInput>
		</section>
	)
}

export default MainSwiperForm;