import React, { useState } from 'react';

//import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { auth, signInWithGoogle } from './component/fbase';

import './LoginForm.css';
 
const LoginForm = () => {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    // 로그인시 이벤트
        const onChange = (event) => {
            const { target: {name, value}}  = event;
            if(name === 'email'){
              setEmail(value);
            } else if( name == 'password'){
              setPassword(value);
            }
          }
          
      
          const onSubmit = async(event) => { 
            event.preventDefault(); 
            try { 
              let data; 
              // if (auth) { /// 새로운 유저 생성 
              //   data = await auth.createUserWithEmailAndPassword(email, password); 
              // } else { // 회원가입 한 유저가 로그인시 이벤트 
                data = await auth.signInWithEmailAndPassword(email,password); 
                /** 아 서버로 세션정보가 안넘어간다 2022.2.3 */
                /*
                fetch("/json/login.php?userInfo=" + JSON.stringify(data),{
                  credentials: 'same-origin',
                  withCredentials: true 
                })
                .then( res => res.json())
                .then((response) => {
                     console.log("response:", response);
      
                   })
                .catch((error) => console.log("error:", error));
                */
              // }
              /* 로그인 정보를 세션 스토리지에 저장 */

              console.log(data); 
            } catch(error) { 
              console.log(error)
              alert(error.code + "\n" + error.message);
            } 
          }
          //const toggleAccount = () => setNewAccount((prev) => !prev);

    return (
<div className="loginBody">
   <div className ="form-signin" >
        

        <Form onSubmit={onSubmit}>
          <div  style={{width :"100%"}}>
          <div className="login-logo-area " style={{margin:"auto"}} >
          <img className="my-4 login-logo"
          src = "https://wekoinonia.org/images/home/hp_logo.jpg" 
          alt = ""
          />
          </div>
          </div>
            <h1 className = "h3 mb-3 fw-normal"> Please sign in </h1>

          <FloatingLabel controlId="floatingInput" label="Email address">
            <Form.Control type="email" placeholder="Enter email" name="email" value={email}  onChange={onChange}/>
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control type="password" placeholder="Password" required name="password" value={password} onChange={onChange} />
          </FloatingLabel>
          <Form.Group className="mb-3 mt-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label=" Remember me" disabled/>
          </Form.Group>
           <Button variant="primary" type="submit" className="w-100">
                Login
            </Button>
            <div className="mt-3" style={{display :"none"}}> 
              <Button variant="primary" type="button" className="w-100" id="googleLogin" onClick={signInWithGoogle}>
                  구글로 로그인
              </Button>
            </div>
            

            <p className="mt-5 mb-3 text-muted">©2022</p>
       </Form> 
    </div>
    {/* 
    <form onSubmit={onSubmit}>
            <input name="email" type="email" placeholder="Email" required value={email} onChange={onChange} />
            <input name="password" type="password" placeholder="password" required value={password} onChange={onChange} />
            <input type="submit" value="Login"/>
    </form> */}
</div>
);
}


export default LoginForm;



