import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import ProgressBar from 'react-bootstrap/ProgressBar';

import qs from 'qs';
import styled from 'styled-components';
import { getUserSession } from '../component/userInfo';

const MyInput = styled.div`
.photo-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;}
  `;
  const MyButton = styled.div`
  .close-delete {
    padding: 12px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

function getExten(str){
    //console.log("getExten :: " + str);
    let aa = str.split('.');
    return aa[aa.length - 1];
}

function PhotoImage ({imageInfo,photoNo}){
    const [image, setImage] = useState(imageInfo);
    const [viewImage, setViewImage] = useState(true);
    const [photoContent, setPhotoContent] = useState(imageInfo.photo_content);
    const [originalPhotoContent, setOriginalPhotoContent] = useState(imageInfo.photo_content);
    const deleteImage = (event) =>{
        if(window.confirm("해당 이미지를 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delImg');
            formData.append('photoNo', photoNo);
            formData.append('photoFileNo', image.photo_file_no);
            fetch('https://work.wekoinonia.org/json/photo.php', {            
                method: 'POST',
                body: formData,
                mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewImage(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }
    }
    const updateComment = (event) =>{
        const userSession = getUserSession();
        const formData = new FormData();
        formData.append('uid', userSession.uid);
        formData.append('action', 'updateComment');
        formData.append('photoNo', photoNo);
        formData.append('photoFileNo', image.photo_file_no);
        formData.append('photoContent', photoContent);
        fetch('https://work.wekoinonia.org/json/photo.php', {            
            method: 'POST',
            body: formData,
            mode: 'cors',
            //headers: new Headers({
                //'Content-Type': 'application/json'
            //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }).then((res) => { return res.json(); //Promise 반환
            }).then((json) => {
                console.log(json); // 서버에서 주는 json데이터가 출력 됨
                if(json.code === "0000"){
                    setOriginalPhotoContent(photoContent); // 동기화 
                } else {
                    alert(json.msg);
                }
            }).catch(error => {
                console.error('Error:', error)
            });                
    }
     
    const onSubmitComment = (event) => { 
        event.preventDefault(); 
        console.log("==================");
        console.log(event);
        updateComment(event);
    }

    let imgsrc = image.imgsrc.startsWith("http") ? image.imgsrc : 'https://work.wekoinonia.org' + image.imgsrc;
    return (
      <>
        {viewImage && 
        
        <Row className="mb-1">
            <Form onSubmit={onSubmitComment}>
                <Card style={{ minWidth: '18rem' , maxWidth: '640px'}}>
                    <MyButton><CloseButton onClick={deleteImage} className="close-delete"/></MyButton>
                    <Card.Img variant="top" src={imgsrc} />
                    <Card.Body>
                        {/* <Card.Title></Card.Title> */}
                        <Card.Text><Form.Control size="sm" type="textarea" placeholder="사진설명" value={photoContent} onChange={e => setPhotoContent(e.target.value)} /></Card.Text>
                        {photoContent != originalPhotoContent && <Button variant="primary" type="submit" className="" size="sm">수정</Button> }
                    </Card.Body>
                </Card>
            </Form>
        </Row>
        
      }
      </>
    );
  }
const PhotoForm = ()=> {
    const params = useParams(); 
    const location = useLocation(); 
	const [submitProcessing, setSubmitProcessing] = useState(false);
	const [progressRate, setProgressRate] = useState(0);
    
	const [selectedFileList, setSelectedFileList] = useState([]);
	const [isSelected, setIsSelected] = useState(false);
    const [photoFiles, setPhotoFiles] = useState([]);
	const [photoNo, setPhotoNo] = useState();
    const [title,setTitle] =useState('');
    const [content,setContent] =useState('');

    const [isRegMode, setIsRegMode] = useState(true); //등록으로 표현 
    const [filekey, setFilekey] = useState(((Math.floor(Math.random() * 1000000) + 1000000) + "").substring(1));
    const serverUrl = "https://work.wekoinonia.org/json/photoFileUpload.php"; //?path=photo&fieldName=filepond&uid=" + userSession.uid + "&filekey=" + filekey;

    const [selected, setSelected] = useState();


    /* 초기값 조회하기 첨부파일 목록 가져오기 */
    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
        });
        console.log(query);
        let photoNo  = query.photoNo;
        // console.log("useEffect() photoDate :: " , photoDate);
        if(photoNo){
            fetchPhotoInfo(photoNo)
        } else {
            setIsRegMode(true);
        }

    },[]);
    const fetchPhotoInfo = (photoNo) => {
        const userSession = getUserSession();
        const formData = new FormData();
        formData.append('uid', userSession.uid);
        formData.append('action', 'photoList');
        formData.append('photoNo', photoNo);
        fetch('https://work.wekoinonia.org/json/photo.php', {
          method: 'POST',
          body: formData,
          }).then((res) => {
              return res.json(); //Promise 반환
          }).then((json) => {
              console.log(json); // 서버에서 주는 json데이터가 출력 됨
            if(json.code !== "0000"){
                alert(json.msg);
            } else if(json.isreg === "N") {
                if(selected != photoNo) setSelected(photoNo);
                setPhotoFiles([]);
                setSelectedFileList([]);
                setIsRegMode(true);
            } else if(json.filecount == 0) {
                if(selected != photoNo) setSelected(photoNo);
                setPhotoFiles([]);
                setSelectedFileList([]);
                setIsRegMode(false);
                setPhotoNo(json.photoNo);
                setTitle(json.photoInfo.title);
                setContent(json.photoInfo.content);
              } else {
                setSelected(photoNo);
                setPhotoFiles(json.filelist);
                setSelectedFileList([]);
                setIsRegMode(false);
                setPhotoNo(json.photoNo);
                setTitle(json.photoInfo.title);
                setContent(json.photoInfo.content);
              }
          }).catch(error => {
            console.error('Error:', error)
          });
       }

    const onSubmit = (event) => { 
        event.preventDefault(); 
        console.log(this);
        console.log(event);
        saveForm(event);
    }
    const uploadImageImgbb = async (imgfile) => {
        var form = new FormData();
        form.set('key', process.env.REACT_APP_IMGBB_API_KEY);
        form.append('image', imgfile);
        
    
        var url = 'https://api.imgbb.com/1/upload' 
    
        const config = {
            method: 'POST',
            mode: 'cors',
            // headers: {
            //     'Accept': 'application/json',
            //     'Access-Control-Allow-Origin': '*',
            //     'Connection': 'keep-alive',
            //     'Content-Type': 'multipart/form-data',
            // },
            body: form
        }
    
        const response = await fetch(url, config);
        const json = await response.json()
        
        console.log(json)        
        return json;
    }
    /*
    data: {id: "dDPV4P3", title: "IMG-2353", url_viewer: "https://ibb.co/dDPV4P3",…}
delete_url: "https://ibb.co/dDPV4P3/a63674bac3057c8b78b7708b6caf65a7"
display_url: "https://i.ibb.co/Yhc1BcK/IMG-2353.jpg"
expiration: "0"
height: "5472"
id: "dDPV4P3"
image: {filename: "IMG-2353.jpg", name: "IMG-2353", mime: "image/jpeg", extension: "jpg",…}
extension: "jpg"
filename: "IMG-2353.jpg"
mime: "image/jpeg"
name: "IMG-2353"
url: "https://i.ibb.co/w4SPhSb/IMG-2353.jpg"
medium: {filename: "IMG-2353.jpg", name: "IMG-2353", mime: "image/jpeg", extension: "jpg",…}
extension: "jpg"
filename: "IMG-2353.jpg"
mime: "image/jpeg"
name: "IMG-2353"
url: "https://i.ibb.co/Yhc1BcK/IMG-2353.jpg"
size: 16,055,330
thumb: {filename: "IMG-2353.jpg", name: "IMG-2353", mime: "image/jpeg", extension: "jpg",…}
extension: "jpg"
filename: "IMG-2353.jpg"
mime: "image/jpeg"
name: "IMG-2353"
url: "https://i.ibb.co/dDPV4P3/IMG-2353.jpg"
time: "1655390120"
title: "IMG-2353"
url: "https://i.ibb.co/w4SPhSb/IMG-2353.jpg"
url_viewer: "https://ibb.co/dDPV4P3"
width: "3648"
    */
    const saveForm = async(event) => { 

        let done = true;
        if(submitProcessing) return;
        setSubmitProcessing(true);
        const userSession = getUserSession();
          // 첨부파일 전송 
          setProgressRate(0); // 처음 0으로 설정
          let sendCount = 0; 
          for (const selectedFile of selectedFileList) {
            try {
                const formData = new FormData();
                //formData.append('file', selectedFile);
                formData.append('path', "photo");
                //formData.append('fieldName', "file");
                formData.append('uid', userSession.uid);
                formData.append('filekey', filekey);
                console.log(selectedFile.name);

/*
                const response = await fetch(
                            serverUrl,
                            {
                                method: 'POST',
                                body: formData,
                                mode: 'cors',
                            }
                        );
                const result = await response.json();
                if(result.code !== "0000"){
                    done = false;
                    setSubmitProcessing(false);
                    alert(result.msg);
                    break;
                }
*/                          
                //console.log(process.env.REACT_APP_IMGBB_API_KEY);
                //imgbbUploader(process.env.REACT_APP_IMGBB_API_KEY , "path/to/your/image.png")
                //.then((response) => console.log(response))
                //.catch((error) => console.error(error));
                let json = await uploadImageImgbb(selectedFile);
                let url = json.data.display_url;
                let del_url = json.data.delete_url;
                let medium_url = json.data.medium ? json.data.medium.url : "";
                let thumb_url = json.data.thumb.url;
                let file_size = json.data.size;
                let file_name = json.data.image.filename;
                let file_type = json.data.image.mime;

                formData.append('file_size',file_size);
                formData.append('file_name',file_name);
                formData.append('file_type',file_type);
                formData.append('url',url);
                formData.append('medium_url',medium_url);
                formData.append('thumb_url',thumb_url);
                formData.append('imgbb_info',JSON.stringify(json));

                const response = await fetch(
                    "https://work.wekoinonia.org/json/photoLinkSave.php",
                    {
                        method: 'POST',
                        body: formData,
                        mode: 'cors',
                    }
                );
                const result = await response.json();
                if(result.code !== "0000"){
                    done = false;
                    setSubmitProcessing(false);
                    alert(result.msg);
                    break;
                }
                sendCount++;
                setProgressRate(sendCount / selectedFileList.length);
            } catch(error) {
                done = false;
                setSubmitProcessing(false);
                console.error('Error:', error);
                break;
            }              
          } // for off
          console.log("done" , done);
          if(done){
            try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'save');
            formData.append('photoNo', selected);
            formData.append('title', title);
            formData.append('content', content);
            formData.append('filekey', filekey);
            formData.append('summary', '');
            
            fetch('https://work.wekoinonia.org/json/photo.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              //headers: new Headers({
                //'Content-Type': 'application/json'
              //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  setSubmitProcessing(false);
                   console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  //fetchPhotoInfo(selected); // 재조회
                  window.location.replace("/PhotoList"); // 목록으로 이동
              }).catch(error => {
                setSubmitProcessing(false);
                console.error('Error:', error)
              });
              
            } catch(error) { 
                setSubmitProcessing(false);
            console.log(error)
            alert(error.code + "\n" + error.message);
            } 
        }
      }

      const onChange = (e) => {
        //setSelected(e.target.value);
        fetchPhotoInfo(e.target.value);
      };

	const changeHandler = (event) => {
        console.log(event.target.files);
        
        let files =  new Array(); 
        for(let i = 0; i < event.target.files.length ; i++){
            files[i] = event.target.files[i];
        }
        let newSelectedFileList = selectedFileList.concat(files);
        
        console.log(newSelectedFileList);

        setSelectedFileList(newSelectedFileList);
        //setSelectedFileList(selectedFileList.concat(event.target.files[0]));
        //console.log(selectedFileList);
        event.target.value = null; // 초기화

		setIsSelected(true);
	};
	return(
        <section className="container">
        <h1> 사진  {isRegMode ? '등록' : '수정'} </h1>
        <MyInput>
            <div className="photo-input">
                <Form onSubmit={onSubmit}  className="mb-4">
                    <FloatingLabel controlId="title" label="제목" className="mb-3">
                        <Form.Control type="text" placeholder="제목" value={title} onChange={e => setTitle(e.target.value)  } required/>
                        <Form.Control.Feedback type="invalid">
                            제목을 입력하시오
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="content" label="내용" className="mb-3">
                        <Form.Control as="textarea" placeholder="내용"   value={content} onChange={e => setContent(e.target.value)} required style={{ height:'200px'}}/>
                        <Form.Control.Feedback type="invalid">
                            내용를 입력하시오
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <input type="hidden" name="filekey" value="{filekey}"/>
                    { submitProcessing ? (<ProgressBar animated now={progressRate} />) : (
                        <Button variant="primary" type="submit" className="w-100" >
                            저장
                        </Button>
                    )}
                </Form> 
                <Row>
                        {photoFiles.map( (photoFile, index) => 
                            <PhotoImage imageInfo={photoFile} photoNo={photoNo} key={index}/>
                        )}    
                </Row>                    
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>첨부파일 </Form.Label>
                    <Form.Control type="file" onChange={changeHandler} title=" " style={{display:'inline'}} multiple />
                </Form.Group>
                {isSelected ? (
				<div>
                    <h5>선택된 파일  <Badge bg="secondary">{selectedFileList.length}</Badge>건 </h5>                    
                    <ListGroup>
                    {selectedFileList.map( (selectedFile) => (
                        <ListGroup.Item key={selectedFile.name}>
                        <i className={"bi bi-filetype-" + getExten(selectedFile.name) }></i>
                        {selectedFile.name},{selectedFile.size}
                        {/*selectedFile.lastModifiedDate.toLocaleDateString()*/}
                         </ListGroup.Item>
                    ))}
                   </ListGroup>
				</div>
			) : (
				<p>첨부하실 이미지를 선택하십시오 </p>
			)}            </div>
        </MyInput>
		</section>
	)
}

export default PhotoForm;