import React ,{useEffect,useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Card , Modal,Form,FloatingLabel} from 'react-bootstrap';
import {getUserSession} from './component/userInfo';


const InputFormModal = (props) => {
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [tel, setTel] = useState('');
    const [responsibility, setResponsibility] = useState('');
    const [email, setEmail] = useState('');
    const [uploadfile, setUploadfile] = useState('');

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>사역자 등록</h4>
              <Form>
                 <FloatingLabel controlId="name" label="이름" className="mb-3">
                    <Form.Control type="text" placeholder="이름"  onChange={e => setName(e.target.value)} required/>
                    <Form.Control.Feedback type="invalid">
                        이름을 입력하시오
                    </Form.Control.Feedback>
                 </FloatingLabel>
                 <FloatingLabel controlId="position" label="직함" className="mb-3">
                    <Form.Control type="text" placeholder="직함"  onChange={e => setPosition(e.target.value)} required/>
                    <Form.Control.Feedback type="invalid">
                        목사,전도사등 직함을 입력하시오
                    </Form.Control.Feedback>
                 </FloatingLabel>
                 <FloatingLabel controlId="tel" label="전화번호" className="mb-3">
                    <Form.Control type="text" placeholder="전화번호"  onChange={e => setTel(e.target.value)} required/>
                    <Form.Control.Feedback type="invalid">
                        전화번호를 입력하시오
                    </Form.Control.Feedback>
                 </FloatingLabel>
                 <FloatingLabel controlId="responsibility" label="담당사역" className="mb-3">
                    <Form.Control type="text" placeholder="담당사역"  onChange={e => setResponsibility(e.target.value)} required/>
                    <Form.Control.Feedback type="invalid">
                        담당사역을 차례로 입력하시오(심방,주일학교등)
                    </Form.Control.Feedback>
                 </FloatingLabel>
                 <FloatingLabel controlId="email" label="이메일" className="mb-3">
                    <Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        이메일을 입력하세요
                    </Form.Control.Feedback>
                 </FloatingLabel>
                 <Form.Group controlId="uploadfile" className="mb-3">
                    <Form.Label>이미지를 입력하세요</Form.Label>
                    <Form.Control type="file" onChange={e => setUploadfile(e.target.files[0])} /> 
                </Form.Group>
              </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button onClick={props.save} variant="primary" >
            Save Changes
          </Button>

        </Modal.Footer>
      </Modal>
    );
  }
  

const Server = () => {
    const userSession = getUserSession();
    const [input, setInput] = useState({});

    useEffect(() => {
           getMinistList();
    }, []);
    const getMinistList = () =>{
        fetch('https://work.wekoinonia.org/json/ministers.php?action=list').then(res => res.json())
        .then(cons => {
          console.log(cons);
        });
      }
    const saveMinist = () =>{
        const formData = new FormData();
        formData.append('uid', userSession.uid);
        formData.append('userEmail', userSession.email);
        formData.append('action', 'save');
        fetch('https://work.wekoinonia.org/json/ministers.php?action=list').then(res => res.json())
        .then(cons => {
          console.log(cons);
        });
    }
    const [modalShow, setModalShow] = React.useState(false);

    return (
<>
<Container className="py-5 text-center">
   <Row>
      <Col lg={6} md={8} className="mx-auto">
        <h1 className="fw-light">섬기는 사람들  </h1>
        <p className="lead text-muted"></p>
        <p>
          <Button href="#"  variant="primary" className="my-2"  onClick={() => setModalShow(true)}>사역자 등록 </Button> 
          <InputFormModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            save={() => {saveMinist()}} />
        </p>
      </Col>
    </Row>
 </Container>
 <div className="album py-5 bg-light">

    <Container>

      <Row xs={1} md={3} sm={1} className="g-3">
      {Array.from({ length: 4 }).map((_, idx) => (

        <Col>
          <Card className="shadow-sm">
            <Card.Img variant="top" className="bd-placeholder-img" width="100%" height="225" src=''/>
            <Card.Body>
              <Card.Text>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <ButtonGroup>
                  <Button variant="outline-secondary" size="sm">Edit</Button>
                </ButtonGroup>
                <small className="text-muted">9 mins</small>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
      </Row>
    </Container>
  </div>
  </>
    )
}

export default Server;