import React, { useState,useEffect,useRef } from 'react'
import { Button, Col, Row ,Form, InputGroup,FormControl} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PagingNav from '../component/PagingNav'
import {getUserSession} from '../component/userInfo';
import Image from 'react-bootstrap/Image'

import './Members.css';

const Members = () => {
    const [paging,setPaging] = useState();
    const [list,setList] = useState([]);
    const [selectedList,setSelectedList] = useState([]);
    const [column,setColumn] = useState('all');
    const [keyword,setKeyword] = useState('');
    const [tempKeyword,setTempKeyword] = useState('');

    useEffect(() => {
        fetchList();
       },[keyword]);
    
    const fetchList = async (page) => {
        const userSession = getUserSession();
        try{
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'list');
            console.log("keyword" , keyword);
            console.log("tempKeyword" , tempKeyword);
            if(keyword) {
                formData.append('keyword', keyword);
                formData.append('column', column);
            }
            if(page) formData.append('page', page);

            let res = await fetch('https://work.wekoinonia.org/json/members.php', {
                method: 'POST',
                body: formData,
                //headers: new Headers({
                    //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            });
            
            let json = await res.json(); //Promise 반환
            console.log(json); // 서버에서 주는 json데이터가 출력 됨
            if(json.paging){
                setPaging(json.paging);
            }
            if(json.list){
                setList(json.list);
            }
          } catch(error) {
            console.error('Error:', error)
          }
       }
    const getPage = (page) => {
        console.log("page = " , page);
        fetchList(page);
    }
    const  selectItem = ({ target }) =>{
        console.log(target);
        console.log(target.checked, target.value);

        if(target.checked) {
            let newSelectedList = selectedList.concat(target.value);
            setSelectedList(newSelectedList);
            console.log(selectedList);
        } else {
            let newSelectedList = selectedList.filter(selected => selected !== target.value);
            console.log(newSelectedList);
            setSelectedList(newSelectedList);
            console.log(selectedList);
        }
    }
    const searchKeyword = (e) =>{
        if(tempKeyword != keyword){
           setKeyword(tempKeyword);
        }
        //fetchList();
    }
    return ( 
<div id="content"> {/* https://startbootstrap.com/previews/sb-admin 참조*/}
{/* Begin Page Content */}
<div className="container-fluid">

    {/* Page Heading */}
    <h1 className="h3 mb-2 text-gray-800">순장방 회원 관리</h1>
    <p className="mb-4"></p>

    {/* DataTales Example */}
    <div className="card shadow mb-4">
        <div className="card-body">
            <div>{/* className="table-responsive" 이게 하단 스크롤바가 생기게 만들어서 */}
                <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                    <div className="dataTable-top">
                        <div className="dataTable-dropdown">
                            <label><select className="dataTable-selector" defaultValue={10} style={{display:"none"}}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    </select></label>
                        </div>
                        <div className="dataTable-search">
                            <InputGroup>
                                <FormControl placeholder="검색" aria-label="검색"  onChange={e => setTempKeyword(e.target.value)} />
                                <Button variant="outline-secondary"  onClick={e => searchKeyword(e)}>조회</Button>
                            </InputGroup>
                        </div>

                    </div>                    
                    <div className="row" >
                        <div className="col-sm-12">
                        <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" role="grid" aria-describedby="dataTable_info" style={{width: '100%'}}>
                            <thead>
                                <tr role="row">
                                {/* <th className="sorting sorting_asc" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-sort="ascending" style={{width: '2rem'}}></th> */}
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="이름: activate to sort column ascending" style={{width: 'auto'}}>이름</th>
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="성별: activate to sort column ascending" style={{width: '2rem'}}>성</th>  */}
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="생년월일: activate to sort column ascending" style={{width: '8rem'}}>생년월일</th> */}
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="이메일: activate to sort column ascending" style={{width: '12rem'}}>이메일</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="전화번호: activate to sort column ascending" style={{width: '10rem'}}>전화번호</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="직분: activate to sort column ascending" style={{width: '10rem'}}>직분</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="순장: activate to sort column ascending" style={{width: '4rem'}}>순장</th>
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="등록일: activate to sort column ascending" style={{width: '12rem'}}>등록일</th> */}
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="사진: activate to sort column ascending" style={{width: '5rem'}}>사진</th>                                
                                </tr>
                            </thead>
                            <tbody>
                            {list.map( (item,index) => {
                                    //console.log(item);
                                    // const url = `/MembersForm?item=${JSON.stringify(item)}`;
                                    const url = `/MembersForm`;
                                    return (                       
                                        <tr key={index}> 
                                            {/* <td><Form.Check  value={item.channel_id + "_" + item.channel} onChange={e => selectItem(e)}/></td> */}
                                            <td><Link to={url} state={{memberInfo: item }}>{item.name}</Link></td>
                                            {/* <td>{item.gender}</td> */}
                                            {/* <td>{item.birth}</td> */}
                                            <td>{item.email}</td> 
                                            <td>{item.phone}</td>
                                            <td>{item.office}</td>
                                            <td>{item.sunjang == "Y" ? "사용" : ""}</td>
                                            {/* <td>{item.idate}</td> */}
                                            <td>{item.photo && (<Image src={item.photo} style={{width: '3em',height: '3em'}}/>)}</td>
                                        </tr>
                                    );
                                }
                            )}    
                            {list.length == 0 && 
                                <tr>
                                    <td colSpan="9" className="text-center" style={{height: "10rem"}}> <p className="mt-5"><b>해당 대상자가 없습니다.</b></p> </td>
                                </tr>
                            }
                            </tbody>
                    </table>
            

                </div>
                </div>
                <Row>
                    <Col lg={10}  className="mx-auto">
                        <PagingNav paging={paging} callback={getPage}/>
                    </Col>
                    
                    <Col lg={2}  className="mx-auto">

                    {/* {selectedList.map( (item,index) => {
                        return (<span key={index}>{item} / </span>);
                        })
                    } */}
                    {/* <Button variant="primary" type="button" style={{ width: '8rem' }}>
                            승인
                    </Button> */}
                    </Col>
                </Row>
                </div>
            </div>
        </div>
    </div>

</div>
{/*.container-fluid */}

</div>   
); 
};

export default Members;