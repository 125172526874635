import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup,FormControl, Tabs, Tab} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import qs from 'qs';
import styled from 'styled-components';
import { getCurrentDate,getCurrentTime,getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';
import VideoChannel from './VideoChannel';
import VideoList from './VideoList';

const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
 `
;
const ChannelReg = () =>{
	const [youtubeUrl,setYoutubeUrl] = useState();
    const [jsonBody, setJsonBody] = useState();
    const [channelId,setChannelId] = useState('');
    const [channelTitle,setChannelTitle] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const analyzeUrl = async(event) => {
        //const urltype = ["https://www.youtube.com/watch", "https://www.youtube.com/playlist","https://www.youtube.com/channel/"];
        if(youtubeUrl.indexOf("https://www.youtube.com/watch") === 0) {
            let param = youtubeUrl.substring( youtubeUrl.indexOf('?'));
            const query = qs.parse(param, {
                ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
            });
            try{
                let yFetchUrl = "https://www.googleapis.com/youtube/v3/videos"
                            + "?id=" + query["v"]
                            + "&fields=items&key=" + process.env.REACT_APP_YOUTUBE_API_KEY + "&part=snippet";
                //console.log("process.env.REACT_APP_YOUTUBE_API_KEY :: " + process.env.REACT_APP_YOUTUBE_API_KEY)
                const response = await fetch(yFetchUrl);
                const { statusCode, statusText, ok, headers } = response;
                const json = await response.json();
                console.log(json);
                setJsonBody(JSON.stringify(json));
                setChannelId(json.items[0].snippet.channelId);
                setChannelTitle(json.items[0].snippet.channelTitle);
                setIsDisabled(false);
            } catch(error) { 
                console.log(error)
                alert(error.code + "\n" + error.message);
            } 
        }
    }
    const saveChannelId = async(event)  =>{
        event.preventDefault();
        const userSession = getUserSession();
        setIsSending(true);
        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'channelSave');
            formData.append('channelId', channelId);
            formData.append('channelTitle', channelTitle);

            
            fetch('https://work.wekoinonia.org/json/video.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  
                  setChannelId('');
                  setChannelTitle('');
                  setIsSending(false);
                  setIsDisabled(true);

              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }
    return (
        <Form onSubmit={e => saveChannelId(e)}  className="mb-4">
        <Form.Label htmlFor="youtubeUrl">분석할 유튜브 url을 넣어주세요 </Form.Label>
        <InputGroup className="mb-3" style={{width:'100%'}}>
            <FormControl type="text" id='youtubeUrl' placeholder="유튜브링크" onChange={e => setYoutubeUrl(e.target.value)  } required/>
            <Button onClick={e => analyzeUrl(e)}>분석</Button>
        </InputGroup>                
        <Form.Group className="mb-3" controlId="youtubeInfo" style={{display:'none'}}>
            <Form.Label>유튜브 리턴정보</Form.Label>
            <Form.Control as="textarea" value={jsonBody} rows={5} />
        </Form.Group>
        <Form.Label htmlFor="channelId">채널아이디</Form.Label>
        <Form.Control type="text" id="channelId" value={channelId} readOnly required/>
        <Form.Label htmlFor="channelTitle">채널 제목 </Form.Label>
        <Form.Control type="text" id="channelTitle" value={channelTitle} readOnly required/>
        <Button variant="primary" type="submit" className="w-100 mt-3" disabled={isDisabled}>
              {isSending ? '저장중...' : '저장'}        
        </Button>

        </Form>        
    );
}



const Video = () => {
    const onSubmit = async(event) => { 
        event.preventDefault();
    }

    return (
        <section className="container mb-3" >
        <h1> 동영상관리 </h1>
        <MyInput>
        
        <Tabs defaultActiveKey="videoList" id="video" className="mb-3">
            <Tab eventKey="videoList" title="동영상관리">
                <VideoList />
            </Tab>
            <Tab eventKey="channelList" title="채널목록">
                <VideoChannel />
            </Tab>
            <Tab eventKey="channelReg" title="신규채널등록">
                <ChannelReg />
            </Tab>
        </Tabs>            

        </MyInput>
        </section>
    )
}

export default Video;