// Myutils.js
export const getSundayList = (maxWeekCount) => {
    // 오늘날짜 구하기 
    let dt = new Date();
    // 오는 선데이 계산 
    let comingSunday =  new Date();
        comingSunday.setDate(dt.getDate() - dt.getDay() + 7); 
    let list = [];
    for(let i = maxWeekCount * -1; i < maxWeekCount ; i++ ){
      comingSunday =  new Date();
      comingSunday.setDate(dt.getDate() + 7 * i - dt.getDay() );
      let year =  comingSunday.getFullYear(); 
       let month = comingSunday.getMonth() + 1;
       let day = comingSunday.getDate();   
       let strdate = year + "-" 
               + (month > 9 ? month : '0' + month ) + "-"
               + (day > 9 ? day : '0' + day)
       list.push(strdate);
//       console.log("i = ", i )
//       console.log("strdate = ",  strdate);
//       console.log("dt.getDate() = ",  dt.getDate());
    }
    return list;
 }
 
 export const getCurrentDate = () => {
    // 오늘날짜 구하기 
    let dt = new Date();

    let year =  dt.getFullYear(); 
    let month = dt.getMonth() + 1;
    let day = dt.getDate();   
    let strdate = year + "-" 
            + (month > 9 ? month : '0' + month ) + "-"
            + (day > 9 ? day : '0' + day);
    return strdate;
 }
 export const getCurrentTime = () => {
    // 오늘날짜 구하기 
    let dt = new Date();

    let hours =  dt.getHours(); 
    let minutes = dt.getMinutes();
    let ret  = (hours > 9 ? hours : '0' + hours )  + ":" 
            + (minutes > 9 ? minutes : '0' + minutes );

    return ret;
 }
