import React, { useState,useEffect } from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getUserSession} from './component/userInfo';
import { Link, NavLink } from 'react-router-dom';
import { getSundayList } from './component/MyUtils';


/*
const uploadImageCallBack  = (file) => {
  return new Promise((resolve, reject)=> {
    const userSession = getUserSession();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", "vision");
    formData.append("fieldName", "file");
    formData.append('uid', userSession.uid);
    fetch("https://work.wekoinonia.org/json/imageUpload.php", {
      method: "POST", 
      body: formData,
      mode: 'cors'

    }).then((res) => {
        return res.json(); //Promise 반환
    })
    .then((json) => {
         resolve({ data: { 
          link: json.url
        }});
        console.log(json); // 서버에서 주는 json데이터가 출력 됨
    }).catch(error => {
      console.error('Error:', error)
      reject(error);
    });
  });
}
*/
function WeeklyItem ({weeklyItem,modifyWeekly}){
  const [item, setItem] = useState(weeklyItem);
  // const weeklyItemOnClick = (event) =>{
  //     modifyWeekly(item);
  // }
  // console.log("Item",item);
  // console.log("weeklyItem",weeklyItem);
  return (
    <Col sm={3}  >
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{item.weeklydate}</Card.Title>
        <Card.Text>
           {item.summary}
        </Card.Text>
        {/* <Button variant="primary" onClick={viewWeekly} data-weekly-item={JSON.stringify(item)}>내용보기</Button> */}
      </Card.Body>
      <NavLink to={`/WeeklyForm?weeklyDate=${item.weeklydate}`}>
        <Card.Img variant="top" src={'http://www.wekoinonia.org' + item.imgsrc} />
      </NavLink>

    </Card>
   </Col>
  );
}
const Weekly = () => {
  const [files, setFiles] = useState([])
  const userSession = getUserSession();
  const [weeklyList, setWeeklyList] = useState([])
  const [theMore, setTheMore] = useState(true);
  const [showReg, setShowReg] = useState(false);

  useEffect(() => {
    fetchWeeklyList('9999-12-31');
   },[]);

  const fetchWeeklyList = (fromdate) => {
    const formData = new FormData();
    formData.append('uid', userSession.uid);
    formData.append('action', 'list');
    formData.append('fromdate', fromdate);
    fetch('https://work.wekoinonia.org/json/weekly.php', {
      method: 'POST',
      body: formData,
      //headers: new Headers({
        //'Content-Type': 'application/json'
      //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }).then((res) => {
          return res.json(); //Promise 반환
      }).then((json) => {
          console.log(json); // 서버에서 주는 json데이터가 출력 됨
          if(json.size == 0) {
             setTheMore(false);
          } else {
            setWeeklyList(weeklyList.concat(json.list));
          }
      }).catch(error => {
        console.error('Error:', error)
      });
   }

  const moreView = (event) =>{
    const lastItem = weeklyList[weeklyList.length-1]
    fetchWeeklyList(lastItem.weeklydate);
  }
  const modifyWeekly = (weeklyItem) => {
    console.log(weeklyItem);
    
    // 1) 날짜를 변경 & 날짜 변경 못하게 readonly 처리 
    // 2) 해당하는 화면 목록 -- filepod에 넣어주기 
    //weeklyItem.filelist
    setFiles(); 
    //setShowReg(true);
 }
 const insertWeekly = (event) =>{
     // 1) 날짜 변경가능하게 설정 
     
     //setShowReg(true);
     // 닫고 나오면서 리스트 변경
 }

  const [filekey, setFilekey] = useState(((Math.floor(Math.random() * 1000000) + 1000000) + "").substring(1));
  //const serverUrl = "https://work.wekoinonia.org/json/imageUpload.php?path=weekly&fieldName=filepond&uid=" + userSession.uid + "&filekey=" + filekey;
  const sundayList = getSundayList(12);
  //console.log(sundayList);
  const [selected, setSelected] = useState(sundayList[0]);
  


  return (
    <section className="container">
      <h1> 주보 </h1>
      <Link to="/WeeklyForm">
        <Button variant="primary" type="button" style={{ width: '12rem' }}>
                    추가 
        </Button>
      </Link>
      <Row>
          {weeklyList.map( weeklyItem => 
               <WeeklyItem weeklyItem={weeklyItem} modifyWeekly={modifyWeekly} />
            )}    
      </Row>
      <Row className="mx-0 justify-content-center" >
        <Col className="mb-2" sm={3}>
        { theMore ? (
        <Button variant="primary" type="button" onClick={moreView} style={{ width: '12rem' }}>
                  더보기 
        </Button>
        ) : (
          <div class="alert alert-primary" role="alert">
             더 이상 없습니다
          </div>
        )}
        </Col>
      </Row>

     </section>

  );
};


export default Weekly;