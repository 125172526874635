import React, { useState,useEffect } from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getUserSession} from '../component/userInfo';
import { Link, NavLink } from 'react-router-dom';
import { getSundayList } from '../component/MyUtils';

import './Photo.css';



function PhotoItem ({photoItem,modifyPhoto,userSession}){
  const [item, setItem] = useState(photoItem);
  const [published, setPublished] = useState(photoItem.publish_yn == 'Y' ? true : false);
  // const photoItemOnClick = (event) =>{
  //     modifyphoto(item);
  // }
  // console.log("Item",item);
  // console.log("photoItem",photoItem);
  let src = item.imgsrc  ? (item.imgsrc.startsWith("http") ? item.imgsrc : 'https://work.wekoinonia.org' + item.imgsrc)
                         : "";
  const publishedChange = (e) => {
    // checked상태이면 발행중지로 변경 non-checked 이면 발행중으로 변경
    let msg = published  ? " 해당 컨텐츠의 발행을 중지하시겠습니까? " : " 해당 컨텐츠를 발행하시겠습니까? ";
    let publishYn = published  ? "N" : "Y"; 
    if(!window.confirm(msg)){
        return ;
    }
    const formData = new FormData();
    formData.append('uid', userSession.uid);
    formData.append('action', 'updatePublished');
    formData.append('photoNo', item.photo_no);
    formData.append('publishYn', publishYn);
    fetch('https://work.wekoinonia.org/json/photo.php', {
      method: 'POST',
      body: formData,
      //headers: new Headers({
        //'Content-Type': 'application/json'
      //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }).then((res) => {
          return res.json(); //Promise 반환
      }).then((json) => {
          console.log(json); // 서버에서 주는 json데이터가 출력 됨
          setPublished(json.publishYn == 'Y' ? true : false);
      }).catch(error => {
        console.error('Error:', error)
      });
   }
  
  return (
    <Col sm={3}  >
    <Card style={{ width: '18rem' }} className="my-1">
      <NavLink to={`/PhotoForm?photoNo=${item.photo_no}`}>
        <Card.Img variant="top" src={src} />
      </NavLink>        
      <Card.Body>
        <Card.Title className="photo-title" title="{{item.title}}">{item.title}</Card.Title>
        <Card.Text className="photo-text">
           {item.content}
        </Card.Text>
        {/* <Button variant="primary" onClick={viewPhoto} data-photo-item={JSON.stringify(item)}>내용보기</Button> */}
      </Card.Body>
      <Card.Footer className="text-muted"><Form.Check type="switch" id={`switch-${item.photo_no}` } value={item.photo_no} onChange={publishedChange} label={published ? "발행중" : "미발행"} checked={published} /></Card.Footer>
      
    </Card>
   </Col>
  );
}
const PhotoList = () => {
  const [files, setFiles] = useState([])
  const userSession = getUserSession();
  const [photoList, setPhotoList] = useState([])
  const [theMore, setTheMore] = useState(true);
  const [showReg, setShowReg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchPhotoList('1');
   },[]);

  const fetchPhotoList = (page) => {
    const formData = new FormData();
    formData.append('uid', userSession.uid);
    formData.append('action', 'list');
    formData.append('page', page);
    fetch('https://work.wekoinonia.org/json/photo.php', {
      method: 'POST',
      body: formData,
      //headers: new Headers({
        //'Content-Type': 'application/json'
      //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }).then((res) => {
          return res.json(); //Promise 반환
      }).then((json) => {
          console.log(json); // 서버에서 주는 json데이터가 출력 됨
          if(json.size == 0) {
             setTheMore(false);
          } else {
            setPhotoList(photoList.concat(json.list));
            setCurrentPage(page);
          }
      }).catch(error => {
        console.error('Error:', error)
      });
   }

  const moreView = (event) =>{
    fetchPhotoList(currentPage * 1 + 1);
  }
  const modifyphoto = (photoItem) => {
    console.log(photoItem);
    
    // 1) 날짜를 변경 & 날짜 변경 못하게 readonly 처리 
    // 2) 해당하는 화면 목록 -- filepod에 넣어주기 
    //photoItem.filelist
    setFiles(); 
    //setShowReg(true);
 }
 const insertphoto = (event) =>{
     // 1) 날짜 변경가능하게 설정 
     
     //setShowReg(true);
     // 닫고 나오면서 리스트 변경
 }

  const [filekey, setFilekey] = useState(((Math.floor(Math.random() * 1000000) + 1000000) + "").substring(1));
  //const serverUrl = "https://work.wekoinonia.org/json/imageUpload.php?path=photo&fieldName=filepond&uid=" + userSession.uid + "&filekey=" + filekey;
  const sundayList = getSundayList(12);
  //console.log(sundayList);
  const [selected, setSelected] = useState(sundayList[0]);
  


  return (
    <section className="container">
      <h1> 사진게시판 </h1>
      <Link to="/PhotoForm">
        <Button variant="primary" type="button" style={{ width: '12rem' }}>
                    사진 등록 
        </Button>
      </Link>
      <Row>
          {photoList.map( (photoItem, index)  => 
               <PhotoItem  key={index} photoItem={photoItem} modifyphoto={modifyphoto}  userSession={userSession}/>
            )}    
      </Row>
      <Row className="mx-0 justify-content-center" >
        <Col className="mb-2" sm={3}>
        { theMore ? (
        <Button variant="primary" type="button" onClick={moreView} style={{ width: '12rem' }}>
                  더보기 
        </Button>
        ) : (
          <div className="alert alert-primary" role="alert" style={{ width: '12rem' , height: '5rem'}}>
              더 이상 없습니다.
          </div>
        )}
        </Col>
      </Row>

     </section>

  );
};


export default PhotoList;