import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup,FormControl, Tabs, Tab,Image,Collapse } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
//import { Link } from 'react-router-dom';

//import qs from 'qs';
//import styled from 'styled-components';
//import { getCurrentDate,getCurrentTime,getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';
import VideoListYoutube from './VideoListYoutube';
import PagingNav from './component/PagingNav'

import './VideoList.css';
/*
const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
 `
;
*/
const MyVideoItem  = ({item,index}) =>{
    const [open,setOpen] = useState(false);
    return (
        <>
            <img src={item.video_img_src} />
            <div className="ms-2 me-auto">
                <div className={"fw-bold video-panel-heading" + (open ? " active" : "")} >
                    
                    <a onClick={() => setOpen(!open)}
                        aria-controls={"collapse-text" + index }
                        aria-expanded={open}
                        >
                        [{item.video_category_name}]{item.video_title}
                    </a>
                </div>
                {/* {item.thumbnail} */}
                <Collapse in={open}>
                    <div id={"collapse-text" + index } dangerouslySetInnerHTML={ {__html: item.video_desc}} >
                        
                    </div>
                </Collapse>                                
            </div>        
        </>
    )
}
const VideoCategorySelect = ({categoryNo,changeCategory}) =>{
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        fetchCategoryList();
    }, []);
    const fetchCategoryList = async () => {
        const userSession = getUserSession();
        try{
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'categoryList');
            let res = await fetch('https://work.wekoinonia.org/json/video.php', {
                method: 'POST',
                body: formData,
            });
            
            let json = await res.json(); //Promise 반환
            console.log(json); // 서버에서 주는 json데이터가 출력 됨 
            if(json.list){
                setCategoryList(json.list);
            }
          } catch(error) { 
            console.error('Error:', error)
          }
       }
    // const changeVideoCategory = (categoryNo)  =>{
        
    // }
    return (
        <Form>
        <Row className="align-items-center">
            <Col sm={4} className="my-1" >
                <div className="float-end"> 카테고리(메뉴) 선택</div>
            </Col> 
            <Col sm={8} className="my-1">
                    <Form.Select onChange={e => {changeCategory(e.target.value)}}  defaultValue="{categoryNo}">
                { categoryList.map( (item,index) => { return (                            
                            <option value={item.video_category_no} key={index}>{item.video_category_name}</option>
                 )})}
                </Form.Select>
            </Col> 
        </Row>
    </Form>
    )
}

const VideoList = () => {
    const [myChannel,setMyChannel] = useState();
    const [cList,setCList] = useState([]);
    const [paging,setPaging] = useState();

    const [myPlaylist,setMyPlaylist] = useState([]);
    const [selectedPlaylist , setSelectedPlaylist]  = useState();

    const [allPlaylist,setAllPlaylist] = useState([]);
    const [myVidelist,setMyVideolist] = useState([]);
    const [youtubeList,setYoutubeList] = useState([]);
    const [selectedChannelId,setSelectedChannelId] = useState();
    const [videoCategoryNo,setVideoCategoryNo] = useState(1);
    
    const getPage = (page) => {
        console.log("page = " , page);
        fetchVideolist(page,videoCategoryNo);
    }

    useEffect(() => {

         fetchVideolist(1,1);
       }, []);

    
      const fetchVideolist = async(page,videoCategoryNo) => {
        const userSession = getUserSession();
        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'list');
            formData.append('videoCategoryNo', videoCategoryNo);
            if(page) formData.append('page', page);

            fetch('https://work.wekoinonia.org/json/video.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  //alert(json.msg);
                  if(json.list){
                    setMyVideolist(json.list);
                    setVideoCategoryNo(videoCategoryNo);
                  }
                  if(json.paging){
                    setPaging(json.paging);
                }
              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }
     return (
        <>
             <Row className="g-5">
                <Col md={6}>
                    <h2>우리홈피</h2>
                    <p>우리 홈피에 등록되어 있는 동영상 목록입니다.</p>
                    <VideoCategorySelect categoryNo={1} changeCategory ={(categoryNo)=>fetchVideolist(1,categoryNo)}/>
                    {myVidelist.length > 0 &&
                        <>
                        <ListGroup className="mb-3">
                        {myVidelist.map( (item,index) => {
                            return (            
                            <ListGroup.Item className="d-flex justify-content-between align-items-start" key={index}>
                                <MyVideoItem item={item} index={index}/>
                            </ListGroup.Item>
                            )})}
                        </ListGroup> 
                        <Row>
                        <Col lg={12}  className="mx-auto">
                            <PagingNav paging={paging} callback={getPage}/>
                        </Col>
                        </Row>
                        </>                
                    } 
                    {myVidelist.length == 0 && 
                        <Row>
                            <Col  className="mb-3">
                                <div className="w-100 bg-light border border-1 d-flex align-items-center justify-content-center" 
                                    style={{minHeight:'30rem'}} ><div>등록된 내용이 없습니다.</div></div> 
                            </Col>
                        </Row>
                    }
                </Col>
                <Col md={6}>
                   <h2>유튜브</h2>
                   <p>유튜브에 등록되어 있는 동영상 목록입니다. 선택하여 우리홈피로 끌어올 수 있습니다.</p>                   
                   <VideoListYoutube videoCategoryNo={videoCategoryNo}/>
                </Col>
            </Row>
        </>
    );
}


export default VideoList;
