import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup,FormControl, Tabs, Tab,Image } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
//import { Link } from 'react-router-dom';

//import qs from 'qs';
//import styled from 'styled-components';
//import { getCurrentDate,getCurrentTime,getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';
/*
const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
 `
;
*/
const VideoPlaylist = ({channel}) =>{
    const [myChannel,setMyChannel] = useState(channel);
    const [myPlaylist,setMyPlaylist] = useState([]);
    const [youtubeList,setYoutubeList] = useState([]);
    useEffect(() => {
        fetchPlaylist();
        // 객체에 myChannel값이 있으므로 myChannel값의 변화에 따라 useEffect가 재실행된다.
      }, [myChannel]);
      const youtubePlaylists = async(channelId) => {
        try{

            const youtubePlaylistsUrl = "https://www.googleapis.com/youtube/v3/playlists?part=snippet"
                                      + "&channelId=" + channelId
                                      + "&key=" + process.env.REACT_APP_YOUTUBE_API_KEY;
            const response = await fetch(youtubePlaylistsUrl);
            const { statusCode, statusText, ok, headers } = response;
            const json = await response.json();
            console.log(json);
            const newList = new Array();
            json.items.map( (item,index) => {
                let playlistId = item.id ; // playlist id
                let playlistName =  item.snippet.title; //playlist title
                let playlistDescription = item.snippet.description;
                let playlistThumbnail = item.snippet.thumbnails.default.url ; // image url
                let jsonItem = JSON.stringify(item);
                newList.push({
                    playlistId : playlistId
                    ,playlistName : playlistName 
                    ,playlistThumbnail : playlistThumbnail
                    ,playlistDescription : playlistDescription
                    ,jsonItem : jsonItem
                })
            });
            setYoutubeList(newList.filter((item,index) => {
                const exists = myPlaylist.find(elt => elt.playlist_id === item.playlistId );
                if(exists) return false;
                else return true;
            })) 
            
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        }               
      } 
 
      const fetchPlaylist = async() => {
        if(!myChannel) return;
        const userSession = getUserSession();
        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'playlist');
            formData.append('channelId',  myChannel.channel_id );

            
            fetch('https://work.wekoinonia.org/json/video.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  //alert(json.msg);
                  if(json.list){
                    setMyPlaylist(json.list);
                  }
              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }    
    const savePlaylist = async(event)  =>{
        event.preventDefault();
        //console.log(event.target.dataset.item);
        let youtubeItem = JSON.parse(event.target.dataset.item);
        const userSession = getUserSession();
        //setIsSending(true);
        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'playlistSave');
            formData.append('channelId', myChannel.channel_id );
            formData.append('playlistId', youtubeItem.playlistId);
            formData.append('playlistName',youtubeItem.playlistName);
            formData.append('playlistDescription',youtubeItem.playlistDescription);
            formData.append('jsonItem',youtubeItem.jsonItem);
            formData.append('thumbnail',youtubeItem.playlistThumbnail);

            
            fetch('https://work.wekoinonia.org/json/video.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  //console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  setYoutubeList([]); // 유튜브 목록 안보이게 
                  fetchPlaylist();

              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }
    const playlistForm = (event)  =>{
        youtubePlaylists(myChannel.channel_id);
    }
    if(!myChannel) {
        if(channel){
            setMyChannel(channel);
        }
    } else if( myChannel.channel_id != channel.channel_id){
        setMyChannel(channel);    
    }

    return (
        <>
         {myChannel &&
             <>
                <div><h3>{myChannel.channel_name} 플레이리스트 </h3></div>
            
                {myPlaylist.length > 0 &&
                    <ListGroup className="mb-3">
                    {myPlaylist.map( (playItem,index) => {
                        return (            
                        <ListGroup.Item className="d-flex justify-content-between align-items-start" key={index}>
                            <img src={playItem.thumbnail} />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{playItem.playlist_name}</div>
                                {/* {playItem.thumbnail} */}
                                {playItem.description}
                            </div>
                            
                        </ListGroup.Item>
                        ); // return
                        } //map
                        )}
                    </ListGroup>         
                } 
                <Button onClick={ e => {playlistForm(e)}}  variant="outline-dark"> {myChannel.channel_name} 유튜브 플레이리스트</Button>
                { youtubeList.length > 0 &&  
                    <ListGroup className="mb-3">
                    {youtubeList.map( (youtubeItem,index) => {
                        return (            
                        <ListGroup.Item className="d-flex justify-content-between align-items-start" key={index}>
                            <img src={youtubeItem.playlistThumbnail} />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{youtubeItem.playlistName}</div>
                                {youtubeItem.playlistDescription}
                            </div>
                            <Button onClick= { e => {savePlaylist(e)}} data-item={JSON.stringify(youtubeItem)}> 추가 </Button>
                        </ListGroup.Item>
                        ); // return
                        } //map
                        )}
                    </ListGroup>        
                }
            </>
         }

        </>
    );
}


export default VideoPlaylist;
