import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { auth } from './component/fbase';
import Container from 'react-bootstrap/Container';

import './Home.css';
import MenuHeader from './component/MenuHeader';
import MyNav from './component/MyNav';
import MyFooter from './component/MyFooter';

const Home = () => {
  // logout event 처리 
  const onClick = async(event) => {
    event.preventDefault(); 
    try { 
      // Sign-out successful.
       await auth.signOut(); 
    } catch(error) { 
      console.log(error) 
    }           
  }
  const user = JSON.parse(sessionStorage.getItem("userInfo"));
  console.log(user);
      
     return (
      <>
        {user.displayName}({user.email})님 안녕하세요
        <h1>  사귐의교회 웹지기 입니다 </h1>  
      </>
);
}


export default Home;



