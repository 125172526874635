import React, { useEffect,  useState } from 'react';

//import Toast from 'react-bootstrap/Toast';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom';

import { auth, signInWithGoogle } from '../component/fbase';
import qs from 'qs';
import styled from 'styled-components';

import './Members.css';
const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
  `

;


const JoinForm = (userInfo) => {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [adminName,setAdminName] = useState('');
    const [user,setUser] = useState(userInfo)
    // 로그인시 이벤트
        const onChange = (event) => {
            const { target: {name, value}}  = event;
            if(name === 'email'){
              setEmail(value);
            } else if( name == 'password'){
              setPassword(value);
            }
          }
          
      
          const onSubmit = async(event) => { 
            event.preventDefault(); 
            try { 
              let data; 
              // if (auth) { /// 새로운 유저 생성 
              //   data = await auth.createUserWithEmailAndPassword(email, password); 
              // } else { // 회원가입 한 유저가 로그인시 이벤트 
                //data = await auth.signInWithEmailAndPassword(email,password); 
                /** 아 서버로 세션정보가 안넘어간다 2022.2.3 */
                /*
                fetch("/json/login.php?userInfo=" + JSON.stringify(data),{
                  credentials: 'same-origin',
                  withCredentials: true 
                })
                .then( res => res.json())
                .then((response) => {
                     console.log("response:", response);
      
                   })
                .catch((error) => console.log("error:", error));
                */
              // }
              /* 로그인 정보를 세션 스토리지에 저장 */

              console.log(data); 
            } catch(error) { 
              console.log(error)
              alert(error.code + "\n" + error.message);
            } 
          }
          //const toggleAccount = () => setNewAccount((prev) => !prev);

    return (
    <>
        <div className="loginBody">
        <div className ="form-signin" >

        { /* xs (<576px).  , sm (≥576px)., md (≥768px), lg(≥992px), xl (≥1200px). and xxl  (≥1400px)*/}
            <div className="weekly-input">
                <Form onSubmit={onSubmit}> 
                    <div  style={{width :"100%"}}>
                      <div className="login-logo-area " style={{margin:"auto"}} >
                        <img className="my-4 login-logo"
                            src = "https://wekoinonia.org/images/home/hp_logo.jpg" 
                            alt = ""
                        />
                        </div>
                    </div>                
                    <h1 className = "h3 mb-3 fw-normal"> 인증된 사용자가 아닙니다.  </h1>
                    <FloatingLabel controlId="floatingInput" label="Email address">
                        <Form.Control type="email" placeholder="Enter email" name="email" value={email}  onChange={onChange}/>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Password">
                        <Form.Control type="password" placeholder="Password" required name="password" value={password} onChange={onChange} />
                    </FloatingLabel>

                    <Button variant="primary" type="submit" className="w-75">
                            가입신청
                    </Button>
                </Form> 
  
         </div>
        
		</div>
        </div>

    </>
    );
    }


export default JoinForm;



