import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {getUserSession} from './component/userInfo';
import { convertToHTML, convertFromHTML } from 'draft-convert';

/*
function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID ##clientid###');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        console.log(response)
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        console.log(error)
        reject(error);
      });
    }
  );
}
*/

const uploadImageCallBack  = (file) => {
  return new Promise((resolve, reject)=> {
    const userSession = getUserSession();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", "vision");
    formData.append("fieldName", "file");
    formData.append('uid', userSession.uid);
    fetch("https://work.wekoinonia.org/json/imageUpload.php", {
      method: "POST", 
      body: formData,
      mode: 'cors'

    }).then((res) => {
        return res.json(); //Promise 반환
    })
    .then((json) => {
         resolve({ data: { 
          link: json.url
        }});
        console.log(json); // 서버에서 주는 json데이터가 출력 됨
    }).catch(error => {
      console.error('Error:', error)
      reject(error);
    });
  });
}

const MyBlock = styled.div`
    .wrapper-class{
        width: 100%;
        margin: 0 auto;
        margin-bottom: 4rem;
    }
  .editor {
    height: 500px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
  }
`;


const Vision = () => {
    
    
  // useState로 상태관리하기 초기값은 EditorState.createEmpty()
  // EditorState의 비어있는 ContentState 기본 구성으로 새 개체를 반환 => 이렇게 안하면 상태 값을 나중에 변경할 수 없음.
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [userInfo,setUserInfo] = useState(sessionStorage.getItem("userInfo"));
  //const [userInfo,setUserInfo] = useState(userInfo());
  const userSession = getUserSession();
  console.log(userSession.uid);

  useEffect(() => {
       getContent();
  }, []);
  

  const onEditorStateChange = (editorState) => {
    // editorState에 값 설정
    setEditorState(editorState);
  };

  /**
   * https://reactrocket.com/post/draft-js-persisting-content/ 에서 참조
   * @param {*} conent 
   */
  // save contents fct
  // const saveContent = (content) =>{
  const saveContent = (content) =>{
    const formData = new FormData();
    formData.append('uid', userSession.uid);
    formData.append('userEmail', userSession.email);
    formData.append('action', 'regVision');
    formData.append('vision', content);
    
    // fetch('https://work.wekoinonia.org/json/introInfo.php', {
    fetch('https://work.wekoinonia.org/json/introInfo.php', {
      method: 'POST',
      body: formData,
      //headers: new Headers({
        //'Content-Type': 'application/json'
      //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'

      //})
    }).then((res) => { return res.json(); //Promise 반환
    }).then((json) => {
        console.log(json); // 서버에서 주는 json데이터가 출력 됨
        alert(json.msg);
    }).catch(error => {
        console.error('Error:', error)
    });                
}

  const onSubmit = async(event) => { 
    event.preventDefault(); 
    try { 

      console.log(editorState);

      //saveContent(convertToRaw(editorState.getCurrentContent()));
      saveContent(convertToHTML(editorState.getCurrentContent()));//stateToHTML html로 변환
                  
    } catch(error) { 
      console.log(error)
      alert(error.code + "\n" + error.message);
    } 
  }


  const getContent = () =>{
    fetch('https://work.wekoinonia.org/json/introInfo.php?action=read').then(res => res.json())
    .then(rawContent => {
      //this.setEditorState({ editorState: EditorState.createWithContent(convertFromRaw(rawContent.vision)) })
      console.log(rawContent.vision);
      setEditorState(EditorState.createWithContent(convertFromHTML(rawContent.vision)));
    });
     
  }
  return (
    <MyBlock>
      <h1> 교회 비전 편집 </h1>
      <Editor
        // 에디터와 툴바 모두에 적용되는 클래스
        wrapperClassName="wrapper-class"
        // 에디터 주변에 적용된 클래스
        editorClassName="editor"
        // 툴바 주위에 적용된 클래스
        toolbarClassName="toolbar-class"
        // 툴바 설정
        toolbar={{
          // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: false },
          image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
        }} 
        placeholder="내용을 작성해주세요."
        // 한국어 설정
        localization={{
          locale: 'ko',
        }}
        // 초기값 설정
        editorState={editorState}
        // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
        onEditorStateChange={onEditorStateChange}
      />
      <Form onSubmit={onSubmit}  className="mb-4">
          <Button variant="primary" type="submit" className="w-100">
                저장
          </Button>
       </Form> 
       <span className="mb-4"></span>
    </MyBlock>
  );
};


export default Vision;