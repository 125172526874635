import React, { useState,useEffect,useRef } from 'react'
import { Button, Col, Row ,Form, InputGroup,Overlay,Tooltip} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PagingNav from '../component/PagingNav'
import {getUserSession} from '../component/userInfo';
import Image from 'react-bootstrap/Image'

import './MainSwiper.css';
const ItemStatus = ({item}) => {
    const [showTooltip,setShowTooltip] = useState();
    const target = useRef(null);

    console.log(item.status);
    return ( 
        <>
        {item.status === "발행예정" && 
           <>
            <span ref={target}       
                  onMouseOver={(event) =>{setShowTooltip(true)}}
                  onMouseOut={(event) => {setShowTooltip(false) }}
            >{item.status}</span>
            <Overlay target={target.current} show={showTooltip} placement="bottom">
                {(props) => (
                <Tooltip {...props}>
                    {item.publish_time}
                </Tooltip>
                )}
           </Overlay>
          </>
        }
        {item.status !== "발행예정" && 
            <span>{item.status}</span>
        }
        </>
    );
}

const MainSwiperList = () => {
    const [paging,setPaging] = useState();
    const [list,setList] = useState([]);
    const [selectedList,setSelectedList] = useState([]);
    const [isTestSite,setIsTestSite] = useState(true);

    useEffect(() => {
        fetchList();
       },[]);
    
    const fetchList = async (page) => {
        const userSession = getUserSession();
        try{
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'list');
            if(page) formData.append('page', page);
            console.log(process.env.REACT_APP_API_KEY)
            let res = await fetch('https://work.wekoinonia.org/json/swiper.php', {
                method: 'POST',
                body: formData,
                //headers: new Headers({
                    //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            });
            
            let json = await res.json(); //Promise 반환
            console.log(json); // 서버에서 주는 json데이터가 출력 됨
            if(json.paging){
                setPaging(json.paging);
            }
            if(json.list){
                setList(json.list);
            }
          } catch(error) {
            console.error('Error:', error)
          }
       }
    const getPage = (page) => {
        console.log("page = " , page);
        fetchList(page);
    }
    const  selectItem = ({ target }) =>{
        console.log(target);
        console.log(target.checked, target.value);

        if(target.checked) {
            let newSelectedList = selectedList.concat(target.value);
            setSelectedList(newSelectedList);
            console.log(selectedList);
        } else {
            let newSelectedList = selectedList.filter(selected => selected !== target.value);
            console.log(newSelectedList);
            setSelectedList(newSelectedList);
            console.log(selectedList);
        }
    }
    const publishingClose = (e) =>{
        if(  selectedList.length <= 0 ) return;
        const userSession = getUserSession();

        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'close');
            formData.append('swiperCategoryNoList', selectedList.join());
            formData.append('swiperCategoryNoCount', selectedList.length);
            formData.append('site', isTestSite ? "test" : "real");

            fetch('https://work.wekoinonia.org/json/swiper.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  //fetchNewsInfo(json.swiper_category_no); // 재조회
                  window.location.reload();
              }).catch(error => {
                console.error('Error:', error);
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }
    const publishingOpen = (e) =>{
        if(  selectedList.length <= 0 ) return;
        const userSession = getUserSession();

        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'open');
            formData.append('swiperCategoryNoList', selectedList.join());
            formData.append('swiperCategoryNoCount', selectedList.length);
            formData.append('site', isTestSite ? "test" : "real");

            
            fetch('https://work.wekoinonia.org/json/swiper.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  //fetchNewsInfo(json.swiper_category_no); // 재조회
                  window.location.reload();
              }).catch(error => {
                console.error('Error:', error);
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }    
    return ( 
<div id="content"> {/* https://startbootstrap.com/previews/sb-admin 참조*/}
{/* Begin Page Content */}
<div className="container-fluid">

    {/* Page Heading */}
    <h1 className="h3 mb-2 text-gray-800">메인배너관리</h1>
    <p className="mb-4"></p>

    {/* DataTales Example */}
    <div className="card shadow mb-4">
        <div className="card-body">
            <div>{/* className="table-responsive" 이게 하단 스크롤바가 생기게 만들어서 */}
                <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                    <div className="dataTable-top">
                        <div className="dataTable-dropdown">
                            <label><select className="dataTable-selector" defaultValue={10}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    </select></label>
                        </div>
                        <div className="dataTable-search"><input className="dataTable-input" placeholder="조회" type="text"/>
                        </div>
                    </div>                    
                    <div className="row" >
                        <div className="col-sm-12">
                        <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" role="grid" aria-describedby="dataTable_info" style={{width: '100%'}}>
                            <thead>
                                <tr role="row">
                                    <th className="sorting sorting_asc" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-sort="ascending" style={{width: '2rem'}}></th>
                                    <th className="sorting sorting_asc" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="No" style={{width: '2rem'}}>No</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="구분으로정렬" style={{width: '6rem'}}>구분</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="제목: activate to sort column ascending" style={{width: 'auto'}}>제목</th>
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="작성자: activate to sort column ascending" style={{width: '6rem'}}>작성자</th> */}
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="작성일: activate to sort column ascending" style={{width: '10rem'}}>작성일</th> */}
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="게시일정: activate to sort column ascending" style={{width: '26rem'}}>게시일정</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="발행상태: activate to sort column ascending" style={{width: '6rem'}}>상태</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="테스트: activate to sort column ascending" style={{width: '6rem'}}>테스트</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="PC 이미지: activate to sort column ascending" style={{width: '4rem'}}>PC 이미지</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="모바일 이미지: activate to sort column ascending" style={{width: '4rem'}}>Mobile 이미지</th>
                                </tr>
                            </thead>
                            <tbody>
                            {list.map( (item,index) => {
                                    //console.log(item);
                                    return (                       
                                        <tr key={index}> 
                                            <td><Form.Check aria-label={`option ${item.swiper_category_no}`} value={item.swiper_category_no} onChange={e => selectItem(e)}/></td>
                                            <td>{item.swiper_category_no}</td>
                                            <td>{item.category_name}</td>
                                            <td><Link to={`/MainSwiperForm?swiperCategoryNo=${item.swiper_category_no}`}>{item.title}</Link></td>
                                            {/* <td>{item.creator}</td> */}
                                            {/* <td>{item.creation_time}</td> */}
                                            <td>{item.start_pub_date} ~ {item.end_pub_date}</td>
                                            <td><ItemStatus item={item} /></td>
                                            <td>{item.test_status}</td>
                                            <td>{item.pc_img_path && (<Image src={'https://work.wekoinonia.org/' + item.pc_img_path} style={{width: '3em',height: '3em'}}/>)}</td>
                                            <td>{item.mo_img_path && (<Image src={'https://work.wekoinonia.org/' + item.mo_img_path} style={{width: '3em',height: '3em'}}/>)}</td>
                                        </tr>
                                    );
                                }
                            )}    
                            {list.length == 0 && 
                                <tr>
                                    <td colSpan="7"> 해당 데이터가 없습니다. </td>
                                </tr>
                            }
                            </tbody>
                    </table>
            

                </div>
                </div>
                <Row>
                    <Col lg={8}  className="mx-auto">
                        <PagingNav paging={paging} callback={getPage}/>
                    </Col>
                    
                    <Col lg={4}  className="mx-auto">

                    {/* {selectedList.map( (item,index) => {
                        return (<span key={index}>{item} / </span>);
                        })
                    } */}
                    <Form.Check type="switch" id={`site` } value="test" onChange={e => { setIsTestSite(e.target.checked)}} label={isTestSite ? "테스트사이트" : "실사이트"} checked={isTestSite} />
                    <InputGroup>
                    <Button variant="primary" type="button" style={{ width: '8rem' }} onClick={e => publishingOpen(e)} >
                                    발행시작
                    </Button>
                    <Button variant="primary" type="button" style={{ width: '8rem' }} onClick={e => publishingClose(e)} >
                                    발행중지 
                    </Button>
                    <Link to="/MainSwiperForm">
                        <Button variant="primary" type="button" style={{ width: '8rem' }}>
                                    신규등록 
                        </Button>
                    </Link>
                    </InputGroup>
                    </Col>
                </Row>
                </div>
            </div>
        </div>
    </div>

</div>
{/*.container-fluid */}

</div>   
); 
};

export default MainSwiperList;