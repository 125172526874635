import React, {useState,useEffect} from 'react';
//import { Route, Switch } from 'react-router-dom';

import { auth } from './component/fbase';
import './App.css';
import LoginForm  from './LoginForm';
import JoinForm  from './members/JoinForm';
import Main  from './Main';
import Container from 'react-bootstrap/Container';


const App = () => {
  
  const [currentUser,setCurrentUser] = useState(null);
  const [signedStatus,setSignedStatus] = useState('');
  const [role,setRole] = useState(false);

  useEffect(() =>{
     //console.log("useEffect App componentDidMount??");
     //console.log(auth);
     // auth.onAuthStateChanged : 사용자가 있을 경우 true, 없을경우 false;
     auth.onAuthStateChanged(user => {
        setCurrentUser(user);
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          //var uid = user.uid;
          getUserRole(user);
          console.log("role" , role);
          if(role){
            sessionStorage.setItem("userInfo",JSON.stringify(user));
            setSignedStatus("signedIn");
          // } else {
          //   sessionStorage.setItem("joinInfo",JSON.stringify(user));
          //   setSignedStatus("join");
          }
          
        } else {
          // User is signed out
          // ...
          sessionStorage.removeItem("userInfo");
          setSignedStatus("signedOut");
        }        
     })

  })
  const getUserRole = async(user) => {
    try{
      const formData = new FormData();
      formData.append('uid', user.uid);
      formData.append('displayName', user.displayName);
      formData.append('email', user.email);
      formData.append('emailVerified', user.emailVerified);
      formData.append('photoURL', user.photoURL);
      let res = await fetch('https://work.wekoinonia.org/json/login.php', {
        method: 'POST',
        body: formData,
        //headers: new Headers({
            //'Content-Type': 'application/json'
        //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        });
    
      let json = await res.json(); //Promise 반환
      console.log("json:", json);
      //if(json.userRole) setRole(true);
      //else setRole(false);
      setRole(true);
      

    } catch(error){
      console.error('Error:', error);
    }
  }

/*
  componentDidMount() {
       console.log("App componentDidMount");
      //console.log(auth);
       auth.onAuthStateChanged(user => {
         this.setState({ currentUser: user });
       })
  }
*/

    return (
      <>
          {signedStatus == "signedIn" 
          ? <Main/>
          :  (signedStatus == "signedOut" ? <LoginForm/> : "loading...")}   
      </>
    );
}

export default App;

