import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { auth } from './component/fbase';
import Container from 'react-bootstrap/Container';

import './Main.css';
import Home from './Home';
import Vision from './Vision';
import Server from './Server';
import WorshipTime from './WorshipTime';
import Map from './Map';
import Video from './Video';
import News from './News';
import NewsForm from './NewsForm';
import NewsWeddingForm from './NewsWeddingForm';
import NewsFuneralForm from './NewsFuneralForm';
import NewsBirthForm from './NewsBirthForm';
import Weekly from './Weekly';
import WeeklyForm from './WeeklyForm';
import Family from './Family';
import Notice from './Notice';
import Members from './members/Members';
import Joinlist from './members/Joinlist';
import MembersForm from './members/MembersForm';
import PhotoList from './photos/PhotoList';
import PhotoForm from './photos/PhotoForm';
import PhotoView from './photos/PhotoView';
import MainSwiperList from './mainSwiper/MainSwiperList';
import MainSwiperForm from './mainSwiper/MainSwiperForm';
import MainPopupList from './mainPopup/MainPopupList';
import MainPopupForm from './mainPopup/MainPopupForm';
import MainToastList from './mainToast/MainToastList';
import MainToastForm from './mainToast/MainToastForm';
import Board from './board/Board';
import BoardForm from './board/BoardForm';
import UserList from './admin/UserList'
import UserForm from './admin/UserForm';
import UserJoin from './admin/UserJoin';


import MyNav from './component/MyNav';
import MyFooter from './component/MyFooter';

const Main = () => {
  // logout event 처리 
  const onClick = async(event) => {
    event.preventDefault(); 
    try { 
      // Sign-out successful.
       await auth.signOut(); 
    } catch(error) { 
      console.log(error) 
    }           
  }
      
      
     return (
<>
      <BrowserRouter>
        <MyNav/>
        <main className="flex-shrink-0" style={{minHeight:'30rem'}}>
            <Container className="mt-4">
              <Routes>
                <Route  path="/" element={<Home/>} /> 
                <Route  path="/Vision" element={<Vision/>} />
                <Route  path="/Server" element={<Server/>} />
                <Route  path="/WorshipTime" element={<WorshipTime/>} />
                <Route  path="/Map" element={<Map/>} />

                <Route  path="/Video" element={<Video/>} />

                <Route  path="/Weekly" element={<Weekly/>} />
                <Route  path="/WeeklyForm" element={<WeeklyForm/>} />
                <Route  path="/Family" element={<Family/>} />
                <Route  path="/Notice" element={<Notice/>} />
                <Route  path="/News" element={<News/>} />
                <Route  path="/NewsForm" element={<NewsForm/>} />
                <Route  path="/NewsWeddingForm" element={<NewsWeddingForm/>} />
                <Route  path="/NewsFuneralForm" element={<NewsFuneralForm/>} />
                <Route  path="/NewsBirthForm" element={<NewsBirthForm/>} />
                <Route  path="/Members" element={<Members/>} />
                <Route  path="/MembersForm" element={<MembersForm/>} />
                <Route  path="/Joinlist" element={<Joinlist/>} />
                <Route  path="/PhotoList" element={<PhotoList/>} />
                <Route  path="/PhotoForm" element={<PhotoForm/>} />
                <Route  path="/PhotoView" element={<PhotoView/>} />
                <Route  path="/MainSwiperList" element={<MainSwiperList/>} />
                <Route  path="/MainSwiperForm" element={<MainSwiperForm/>} />
                <Route  path="/MainPopupList" element={<MainPopupList/>} />
                <Route  path="/MainPopupForm" element={<MainPopupForm/>} />
                <Route  path="/MainToastList" element={<MainToastList/>} />
                <Route  path="/MainToastForm" element={<MainToastForm/>} />
                <Route  path="/Board/:boardId" element={<Board/>} />
                <Route  path="/BoardForm/:boardId" element={<BoardForm/>} />
                <Route  path="/UserForm" element={<UserForm/>} />
                <Route  path="/UserList" element={<UserList/>} />
                <Route  path="/UserJoin" element={<UserJoin/>} />
              </Routes>
            </Container>
        </main>
        <MyFooter/>
      </BrowserRouter>
</>
);
}


export default Main;

{
    /*
    * 소개 - intro 
– 교회 비전소개 vision
- 섬기는 사람 server
- 예배시간 안내  worship time
- 오시는 길 map

* 예배 worship
– 예배 영상(Youtube 연동) video 게시판으로 

* 안내 
– 주보 및 교회 소식 weekly
  (주보는 홈페이지로 바로 올리고, 교회 소식은 게시물? 인스타?)
- 새가족 안내 family
- 공지사항(게시판) Notice
*/
  }

