import React, { useState,useEffect,useRef } from 'react'
import { Button, Col, Row ,Form, InputGroup,Overlay,Tooltip} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PagingNav from '../component/PagingNav'
import {getUserSession} from '../component/userInfo';
import Image from 'react-bootstrap/Image'

import './Members.css';

const Joinlist = () => {
    const [paging,setPaging] = useState();
    const [list,setList] = useState([]);
    const [selectedList,setSelectedList] = useState([]);
    useEffect(() => {
        fetchList();
       },[]);
    
    const fetchList = async (page) => {
        const userSession = getUserSession();
        try{
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'list_request');
            formData.append('confirm', 'R');

            if(page) formData.append('page', page);
            console.log(process.env.REACT_APP_API_KEY)
            let res = await fetch('https://work.wekoinonia.org/json/members.php', {
                method: 'POST',
                body: formData,
                //headers: new Headers({
                    //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            });
            
            let json = await res.json(); //Promise 반환
            console.log(json); // 서버에서 주는 json데이터가 출력 됨
            if(json.paging){
                setPaging(json.paging);
            }
            if(json.list){
                setList(json.list);
            }
          } catch(error) {
            console.error('Error:', error)
          }
       }
    const getPage = (page) => {
        console.log("page = " , page);
        fetchList(page);
    }
    const  selectItem = ({ target }) =>{
        console.log(target);
        console.log(target.checked, target.value);

        if(target.checked) {
            let newSelectedList = selectedList.concat(target.value);
            setSelectedList(newSelectedList);
            console.log(selectedList);
        } else {
            let newSelectedList = selectedList.filter(selected => selected !== target.value);
            console.log(newSelectedList);
            setSelectedList(newSelectedList);
            console.log(selectedList);
        }
    }
    const deleteMember = (e) =>{
        console.log( " selectedList " , selectedList);
        if(  selectedList.length <= 0 ) {
            alert(" 하나 이상을 선택하셔야 합니다. ");
            return;
        }

        // 삭제하시겠습니까?
        if (window.confirm("해당 신청을 거절하시겠습니까?")) {
            confirmRejectMember('reject');    
        }
          
        
    }
    const confirmMember = (e) =>{
        if(  selectedList.length <= 0 ) {
            alert(" 하나 이상을 선택하셔야 합니다. ");
            return;
        }

        confirmRejectMember('confirm');
    }
    const confirmRejectMember = (action) =>{
        console.log( " selectedList " , selectedList);
 
        if(  selectedList.length <= 0 ) return;
        const userSession = getUserSession();

        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', action);
            formData.append('memberList', selectedList.join());
            formData.append('memberCount', selectedList.length);

            
            fetch('https://work.wekoinonia.org/json/members.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  //fetchNewsInfo(json.news_no); // 재조회
                  window.location.reload();
              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
      
    }

    return ( 
<div id="content"> {/* https://startbootstrap.com/previews/sb-admin 참조*/}
{/* Begin Page Content */}
<div className="container-fluid">

    {/* Page Heading */}
    <h1 className="h3 mb-2 text-gray-800">순장방 가입신청목록</h1>
    <p className="mb-4"></p>

    {/* DataTales Example */}
    <div className="card shadow mb-4">
        <div className="card-body">
            <div>{/* className="table-responsive" 이게 하단 스크롤바가 생기게 만들어서 */}
                <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                    <div className="dataTable-top">
                        <div className="dataTable-dropdown"  style={{display:"none"}}>
                            <label><select className="dataTable-selector" defaultValue={10}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    </select></label>
                        </div>
                        <div className="dataTable-search"  style={{display:"none"}}><input className="dataTable-input" placeholder="조회" type="text"/>
                        </div>
                    </div>                    
                    <div className="row" >
                        <div className="col-sm-12">
                        <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" role="grid" aria-describedby="dataTable_info" style={{width: '100%'}}>
                            <thead>
                                <tr role="row">
                                    <th className="sorting sorting_asc" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-sort="ascending" style={{width: '2rem'}}></th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="이름: activate to sort column ascending" style={{width: 'auto'}}>이름</th>
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="성별: activate to sort column ascending" style={{width: '2rem'}}>성별</th>  */}
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="생년월일: activate to sort column ascending" style={{width: '8rem'}}>생년월일</th>  */}
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="이메일: activate to sort column ascending" style={{width: '12rem'}}>이메일</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="전화번호: activate to sort column ascending" style={{width: '10rem'}}>전화번호</th>
                                    {/* <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="직분: activate to sort column ascending" style={{width: '10rem'}}>직분</th> */}
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="순장방: activate to sort column ascending" style={{width: '6rem'}}>순장방</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="승인: activate to sort column ascending" style={{width: '4rem'}}>승인</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="등록일: activate to sort column ascending" style={{width: '12rem'}}>등록일</th>
                                    <th className="sorting" tabIndex="0" aria-controls="dataTable" rowSpan="1" colSpan="1" aria-label="사진: activate to sort column ascending" style={{width: '5rem'}}>사진</th>
                                </tr>
                            </thead>
                            <tbody>
                            {list.map( (item,index) => {
                                    //console.log(item);
                                    return (                       
                                        <tr key={index}> 
                                            <td><Form.Check  value={item.channel_id + "_" + item.channel} onChange={e => selectItem(e)}/></td>
                                            <td><Link to={`/MembersForm?item=${JSON.stringify(item)}`}></Link>{item.name}</td>
                                            {/* <td>{item.gender}</td> */}
                                            {/* <td>{item.birth}</td> */}
                                            <td>{item.email}</td> 
                                            <td>{item.phone}</td>
                                            {/* <td>{item.office}</td> */}
                                            <td>{item.sunjang == "Y" ? "사용" : ""}</td>
                                            <td>{item.confirm == "Y" ? "승인" : (item.confirm == "R" ? "신청" : "")}</td>
                                            <td>{item.idate}</td>
                                            <td>{item.photo && (<Image src={item.photo} style={{width: '3em',height: '3em'}}/>)}</td>
                                        </tr>
                                    );
                                }
                            )}    
                            {list.length == 0 && 
                                <tr>
                                    <td colSpan="8" className="text-center" style={{height: "10rem"}}> <p className="mt-5"><b>가입신청자가 없습니다.</b></p> </td>
                                </tr>
                            }
                            </tbody>
                    </table>
            

                </div>
                </div>
                <Row>
                    <Col lg={10}  className="mx-auto">
                        <PagingNav paging={paging} callback={getPage}/>
                    </Col>
                    
                    <Col lg={2}  className="mx-auto">

                    {/* {selectedList.map( (item,index) => {
                        return (<span key={index}>{item} / </span>);
                        })
                    } */}
                    <InputGroup>
                        <Button variant="danger" type="button" style={{ width: '4rem' }}  onClick={e => deleteMember(e)} >
                                삭제
                        </Button>
                        <Button variant="primary" type="button" style={{ width: '4rem' }}  onClick={e => confirmMember(e)} >
                                승인
                        </Button>
                    </InputGroup>
                    </Col>
                </Row>
                </div>
            </div>
        </div>
    </div>

</div>
{/*.container-fluid */}

</div>   
); 
};

export default Joinlist;