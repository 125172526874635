import React from 'react';
import { auth } from './fbase';
import { Link} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';

const MyNav = () => {

    const onClickSignOut = async(event) => {
        event.preventDefault(); 
        try { 
          // Sign-out successful.
           await auth.signOut(); 
        } catch(error) { 
          console.log(error) 
        }           
      }
    
    return (
<header>
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Container>
    <Navbar.Brand href="/">사귐의교회 웹지기</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
      <Nav.Link href="/">Home</Nav.Link>
                  <NavDropdown title="관리자" id="nav-intro">
                    {/* <NavDropdown.Item eventKey="1.1" href="/Vision">교회비전</NavDropdown.Item> */}
                    {/* <NavDropdown.Item eventKey="1.2" href="/Server">섬기는 사람</NavDropdown.Item>
                    <NavDropdown.Item eventKey="1.3" href="/WorshipTime">예배시간 안내</NavDropdown.Item> */}
                    {/* <NavDropdown.Divider /> */}
                    {/* <NavDropdown.Item eventKey="4.4" href="/Map">오시는 길</NavDropdown.Item> */}
                    <NavDropdown.Item eventKey="1.4" href="/UserList">관리자계정</NavDropdown.Item>
                    <NavDropdown.Item eventKey="1.5" href="/MainSwiperList">배너관리</NavDropdown.Item>
                    <NavDropdown.Item eventKey="1.6" href="/MainPopupList">팝업관리</NavDropdown.Item>
                    <NavDropdown.Item eventKey="1.7" href="/MainToastList">TOAST관리</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="예배" id="nav-worship">
                    <NavDropdown.Item eventKey="2.1" href="/Video">예배영상</NavDropdown.Item>

                  </NavDropdown>
                  <NavDropdown title="소식" id="nav-intro">
                    <NavDropdown.Item eventKey="3.1" href="/Weekly">주보</NavDropdown.Item>
                    <NavDropdown.Item eventKey="3.2" href="/News">교회소식</NavDropdown.Item>
                    <NavDropdown.Item eventKey="3.3" href="/Board/archive">자료실</NavDropdown.Item>
                    {/* <NavDropdown.Item eventKey="3.3" href="/Family">새가족 안내</NavDropdown.Item> */}
                    <NavDropdown.Item eventKey="3.4" href="/PhotoList">사진게시판</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="순장방" id="nav-sunjang">
                    <NavDropdown.Item eventKey="3.1" href="/Joinlist">가입신청</NavDropdown.Item>
                    <NavDropdown.Item eventKey="3.2" href="/Members">회원관리</NavDropdown.Item>
                  </NavDropdown>
                  </Nav>
        
        <Navbar.Text>
            <a href="#"  onClick={onClickSignOut}>Sign out</a>
        </Navbar.Text>

      </Navbar.Collapse>
    </Container>
  </Navbar>
  </header>
  )};
export default MyNav;