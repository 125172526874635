import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup,FormControl, Tabs, Tab} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import VideoPlaylist from './VideoPlaylist';
//import { Link } from 'react-router-dom';

//import qs from 'qs';
//import styled from 'styled-components';
//import { getCurrentDate,getCurrentTime,getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';
/*
const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
 `
;
*/
const VideoChannel = () =>{
    const [channelList,setChannelList] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState();
    useEffect(() => {
        fetchChannelList();
       },[]);
    
    const fetchChannelList = async (page) => {
        const userSession = getUserSession();
        try{
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'channelList');
            let res = await fetch('https://work.wekoinonia.org/json/video.php', {
                method: 'POST',
                body: formData,
            });
            
            let json = await res.json(); //Promise 반환
            console.log(json); // 서버에서 주는 json데이터가 출력 됨 
            if(json.list){
                setChannelList(json.list);
            }
          } catch(error) { 
            console.error('Error:', error)
          }
       }

    return (
    <Tab.Container id="channlist-tab" defaultActiveKey="#link0">
    <Row>
        <Col sm={2}>
            <ListGroup>
                {channelList.map( (item,index) => {
                return (            
                <ListGroup.Item className="d-flex justify-content-between align-items-start" action href={`#link${index}`} key={index}>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{item.channel_name}</div>
                        {/* {item.channel_id} */}
                    </div>
                </ListGroup.Item>
                );
                }
                )}
        </ListGroup>
        </Col>
        <Col sm={10}>
        <Tab.Content>
            {channelList.map( (item,index) => {
                return (            
            <Tab.Pane eventKey={`#link${index}`} key={index}>
              <VideoPlaylist channel={item}/>
            </Tab.Pane>
                );})}
        </Tab.Content>
        </Col>
    </Row>
    </Tab.Container>        
    )
}
export default VideoChannel;
