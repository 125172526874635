 export const getUserSession = () =>{
    const user = JSON.parse(sessionStorage.getItem("userInfo"));
    
    return {
        "uid" : user.uid,
        "email" : user.email
    };
        
}

//export const getUserSession ; // = getUserSession();