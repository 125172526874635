import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton,FloatingLabel,InputGroup,FormControl, Tabs, Tab,Image,Collapse  } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
//import { Link } from 'react-router-dom';

//import qs from 'qs';
//import styled from 'styled-components';
//import { getCurrentDate,getCurrentTime,getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';
import VideoInputForm from './VideoInputForm';

/*
const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.content-type {
   border : 1px solid #ced4da;
   padding: 1rem 0.75rem;
   position: relative;
   display: flex;
   border-radius: 0.25rem;
}
.content-type>label  {
   opacity: .65;
   transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); 
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   padding: 1rem 0.75rem;
   pointer-events: none;
   border: 1px solid transparent;
   transform-origin: 0 0;
}
.content-border {
    border : 1px solid #ced4da;
    border-radius: 0.25rem;
 }
 `
;
*/
const MyYoutubeItem  = ({item,index,fetchYoutubeInfo}) =>{
    const [open,setOpen] = useState(false);
    return (
        <>
            <img src={item.videoImgSrc} />
            <div className="ms-2 me-auto">
                <div className={"fw-bold video-panel-heading" + (open ? " active" : "")} >
                    
                    <a onClick={() => setOpen(!open)}
                        aria-controls={"collapse-text" + index }
                        aria-expanded={open}
                        >
                        {item.videoTitle}
                    </a>
                </div>
                {/* {item.thumbnail} */}
                <Collapse in={open}>
                    <div id={"collapse-text" + index } dangerouslySetInnerHTML={ {__html: item.videoDesc}} >
                        
                    </div>
                </Collapse>                                
            </div>        

            <Button onClick= { e => {fetchYoutubeInfo(e)}} data-item={JSON.stringify(item)}> 추가 </Button>
            {/* <Button onClick= { e => {saveVideoOnClick(e)}} data-item={JSON.stringify(item)}> 바로추가 </Button> */}
        </>
    )
}
const VideoListYoutube = (props) => {
    const [cList,setCList] = useState([]);


    const [allPlaylist,setAllPlaylist] = useState([]);
    const [youtubeList,setYoutubeList] = useState([]);
    const [selectedChannelId,setSelectedChannelId] = useState();
    const [selectedPlaylistId,setSelectedPlaylistId] = useState();

    const [myVideoInfo,setMyVideoInfo] = useState();

    useEffect(() => {

        fetchPlaylistAll();
      }, []);

    useEffect(() => {

      fetchChannelList();
      }, []);
    
      const youtubeVideolists = async(playlistId) => {
        try{

            const youtubeVideolistsUrl = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet"
                                      // + "&channelId=" + channelId
                                      + "&playlistId=" + playlistId
                                      + "&key=" + process.env.REACT_APP_YOUTUBE_API_KEY
                                      + "&maxResults=10";
            const response = await fetch(youtubeVideolistsUrl);
            const { statusCode, statusText, ok, headers } = response;
            const json = await response.json();
            console.log(json);
            const newList = new Array();
            json.items.map( (item,index) => {
                if(item.snippet.title ==  "Deleted video") return false;
                if(item.snippet.title ==  "Private video") return false;
                

                let videoId = item.snippet.resourceId.videoId ; // videoId
                let videoTitle =  item.snippet.title; // video title
                let videoDesc = item.snippet.description;
                let videoImgSrc = item.snippet.thumbnails.default.url ; // image url
                let published = item.snippet.publishedAt;
                let channelId = item.snippet.channelId ; // channelId
                let playlistId = item.snippet.playlistId ; // channelId

                newList.push({
                    videoId : videoId
                    ,videoTitle : videoTitle 
                    ,videoDesc : videoDesc
                    ,videoImgSrc : videoImgSrc
                    ,published : published
                    ,channelId : channelId
                    ,playlistId : playlistId
                })
            });
            // setYoutubeList(newList.filter((item,index) => {
            //     const exists = myPlaylist.find(elt => elt.video_id === item.videoId );
            //     if(exists) return false;
            //     else return true;
            // })) 
            setYoutubeList(newList);
            
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        }               
      } 
 
    const fetchPlaylistAll = async() => {
        const userSession = getUserSession();
        try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'playlist');

            fetch('https://work.wekoinonia.org/json/video.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  //alert(json.msg);
                  if(json.list){
                    setAllPlaylist(json.list);
                  }
              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }    
    
    const fetchChannelList = async () => {
        const userSession = getUserSession();
        try{
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'channelList');
            let res = await fetch('https://work.wekoinonia.org/json/video.php', {
                method: 'POST',
                body: formData,
            });
            
            let json = await res.json(); //Promise 반환
            console.log(json); // 서버에서 주는 json데이터가 출력 됨 
            if(json.list){
                setCList(json.list);
                setSelectedChannelId(json.list[0].channel_id)
                console.log(cList);
                //console.log(json.list);
            }
          } catch(error) { 
            console.error('Error:', error)
          }
       }

    const saveVideo = async(youtubeItem)  =>{
        const userSession = getUserSession();
        //setIsSending(true);
        try { 
            console.log("saveVideo :: ", youtubeItem);
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'videoSave');
            formData.append('channelId', youtubeItem.channelId );
            formData.append('playlistId', youtubeItem.playlistId);
            
            formData.append('videoId',youtubeItem.videoId);
            formData.append('videoTitle',youtubeItem.videoTitle);
            formData.append('videoDesc',youtubeItem.videoDesc);
            formData.append('videoImgSrc',youtubeItem.videoImgSrc);
            formData.append('published',youtubeItem.published);

            formData.append('videoCategoryNo',youtubeItem.videoCategoryNo);

            fetch('https://work.wekoinonia.org/json/video.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  //console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  setYoutubeList([]); // 유튜브 목록 안보이게 
              }).catch(error => {
                console.error('Error:', error)
              });
        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        } 
    }
    const saveVideoOnClick = (event)  =>{
        event.preventDefault();
        //console.log(event.target.dataset.item);
        let youtubeItem = JSON.parse(event.target.dataset.item);
        youtubeItem.videoCategoryNo = props.videoCategoryNo
        saveVideo(youtubeItem);

    }
    function videolistForm(event) {
        if(selectedPlaylistId)
            youtubeVideolists(selectedPlaylistId);
    }
        // 1) 여기서 유튜브 비디오 정보 얻어오기 ...
        //    https://www.googleapis.com/youtube/v3/videos?id=IWnvcZUk7qI&key=AIzaSyAzvNMjGIorvx0F_wz0HmE9_DGpbX-jt8o&part=snippet
        // 2) return 받으면 코이노니아 영상 등록되어 있는지 정보 확인호출 
        // 3) return 받으면 신규인지, 수정인지 화면 표시하고 등록할 내용 화면 표시 
        // 화면에서 저장 클릭하면 saveVideo 호출 ...

    const fetchYoutubeInfo = async(event)  =>{
        event.preventDefault();
        let youtubeItem = JSON.parse(event.target.dataset.item);
        const userSession = getUserSession();

        try{
            /**
             * 1) 여기서 유튜브 비디오 정보 얻어오기 ...
             */
            const youtubeVideoInfoUrl = "https://www.googleapis.com/youtube/v3/videos?part=snippet"
            + "&id=" + youtubeItem.videoId 
            + "&key=" + process.env.REACT_APP_YOUTUBE_API_KEY;
    

            const youtubeResponse = await fetch(youtubeVideoInfoUrl);
            const { statusCode, statusText, ok, headers } = youtubeResponse;
            const youtubeJson = await youtubeResponse.json();
            console.log("fetchYoutubeInfo(youtube) : ", youtubeJson);
            console.log("fetchYoutubeInfo(youtubeItem 위와 비교) : ", youtubeItem);
            /**
             * 2) return 받으면 코이노니아 영상 등록되어 있는지 정보 확인호출 
             */
             const formData = new FormData();
             formData.append('uid', userSession.uid);
             formData.append('action', 'videoInfo');
             formData.append('videoId', youtubeItem.videoId );
 
             fetch('https://work.wekoinonia.org/json/video.php', {
               method: 'POST',
               body: formData,
               mode: 'cors',
               }).then((res) => { return res.json(); //Promise 반환
               }).then((json) => {
                   console.log("fetchYoutubeInfo(/json/video.php) : ", json); // 서버에서 주는 json데이터가 출력 됨
                   /**
                    * 3) return 받으면 신규인지, 수정인지 화면 표시하고 등록할 내용 화면 표시 
                    */
                   setMyVideoInfo({"youtubeInfo": youtubeItem , "serverInfo" : json, "show" : true});
               }).catch(error => {
                 console.error('Error:', error)
               });
 

        } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
        }               
    }
    return (
        <div  className="bg-light border px-2">
            <Form>
                <Row className="align-items-center">
                    <Col sm={4} className="my-1">
                            <Form.Select onChange={e => {setSelectedChannelId(e.target.value)}}  defaultValue="선택해주세요">
                            <option> 채널 선택 </option>
                        { cList.map( (item,index) => { return (                            
                            <option value={item.channel_id} key={index}>{item.channel_name}</option>
                        )})}
                        </Form.Select>
                    </Col> 
                    <Col sm={4} className="my-1">
                        <Form.Select  onChange={e => {setSelectedPlaylistId(e.target.value)}}  defaultValue="선택해주세요">
                            <option> 플레이리스트 선택 </option>
                        {allPlaylist.filter(elm => selectedChannelId == elm.channel_id).map((item,index) => { return (                           
                            <option value={item.playlist_id} key={index}>{item.playlist_name}</option>
                        )})}                             
                        </Form.Select>
                    </Col>
                    <Col xs="auto" className="my-1">
                        <Button onClick={ e => {videolistForm(e)}}  variant="outline-dark"> 유튜브 비디오목록</Button>
                    </Col>
                </Row>
            </Form>
            { youtubeList.length > 0 &&  
                <ListGroup className="mb-3">
                {youtubeList.map( (youtubeItem,index) => {
                    return (            
                    <ListGroup.Item className="d-flex justify-content-between align-items-start" key={index}>
                         <MyYoutubeItem item={youtubeItem} index={index} fetchYoutubeInfo={fetchYoutubeInfo}/>
                    </ListGroup.Item>
                    ); // return
                    } //map
                    )} 
                </ListGroup>        
            }
            {/* <VideoInputForm videoInfo={myVideoInfo} /> */}
            {myVideoInfo && <VideoInputForm videoInfo={myVideoInfo} 
                                             close={()=>{setMyVideoInfo({"youtubeInfo": {} ,"serverInfo": {} , "show":false});}} 
                                             saveVideo={saveVideo}
                                             videoCategoryNo={props.videoCategoryNo}
                                             />}
        </div>
    );
}


export default VideoListYoutube;
