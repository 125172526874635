import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, ListGroup, Row ,Card,CloseButton} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import qs from 'qs';
import styled from 'styled-components';
import { getSundayList } from './component/MyUtils';
import { getUserSession } from './component/userInfo';

const MyInput = styled.div`
.weekly-input {
  position: relative;
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;}
`;

function getExten(str){
    let aa = str.split('.');
    return aa[aa.length - 1];
}

function WeeklyImage ({imageInfo,weeklyNo}){
    const [image, setImage] = useState(imageInfo);
    const [viewImage, setViewImage] = useState(true);
    const deleteImage = (event) =>{
        if(window.confirm("해당 이미지를 삭제하시겠습니까?")){
            const userSession = getUserSession();
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'delImg');
            formData.append('weeklyNo', weeklyNo);
            formData.append('fileno', image.file_no);
            fetch('https://work.wekoinonia.org/json/weekly.php', {            
                method: 'POST',
                body: formData,
                mode: 'cors',
                //headers: new Headers({
                  //'Content-Type': 'application/json'
                //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then((res) => { return res.json(); //Promise 반환
                }).then((json) => {
                    console.log(json); // 서버에서 주는 json데이터가 출력 됨
                    if(json.code === "0000"){
                        setViewImage(false);
                    }
                    alert(json.msg);
                }).catch(error => {
                  console.error('Error:', error)
                });                
        }
    }
    
    return (
      <>
        {viewImage && 
        <Col sm={3} >
        <Card style={{ width: '18rem' }}>
        <CloseButton onClick={deleteImage} />
            <Card.Img variant="top" src={'http://www.wekoinonia.org' + image.imgsrc} />
        </Card>
        </Col>
      }
      </>
    );
  }
const WeeklyForm = ()=> {
    const params = useParams(); 
    const location = useLocation(); 

	const [selectedFileList, setSelectedFileList] = useState([]);
	const [isSelected, setIsSelected] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
	const [weeklyNo, setWeeklyNo] = useState();
    const [summary, setSummary] = useState();

    const [isRegMode, setIsRegMode] = useState(true); //등록으로 표현 
    const [filekey, setFilekey] = useState(((Math.floor(Math.random() * 1000000) + 1000000) + "").substring(1));
    const serverUrl = "https://work.wekoinonia.org/json/imageUpload.php"; //?path=weekly&fieldName=filepond&uid=" + userSession.uid + "&filekey=" + filekey;
    const sundayList = getSundayList(12);
    const [selected, setSelected] = useState();


    /* 초기값 조회하기 첨부파일 목록 가져오기 */
    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
        });
        console.log(query);
        let weeklyDate  = query.weeklyDate;
        // console.log("useEffect() weeklyDate :: " , weeklyDate);
        
        if(!weeklyDate){
            weeklyDate = sundayList[0];
        }
        fetchWeeklyInfo(weeklyDate)

    },[]);
    const fetchWeeklyInfo = (weeklyDate) => {
        const userSession = getUserSession();
        const formData = new FormData();
        formData.append('uid', userSession.uid);
        formData.append('action', 'imgList');
        formData.append('weeklyDate', weeklyDate);
        fetch('https://work.wekoinonia.org/json/weekly.php', {
          method: 'POST',
          body: formData,
          }).then((res) => {
              return res.json(); //Promise 반환
          }).then((json) => {
              console.log(json); // 서버에서 주는 json데이터가 출력 됨
              if(json.code !== "0000"){
                alert(json.msg);
            } else if(json.isreg === "N") {
                if(selected != weeklyDate) setSelected(weeklyDate);
                setUploadedFiles([]);
                setSelectedFileList([]);
                setIsRegMode(true);
            } else if(json.filecount == 0) {
                if(selected != weeklyDate) setSelected(weeklyDate);
                setUploadedFiles([]);
                setSelectedFileList([]);
                setIsRegMode(false);
                setWeeklyNo(json.weeklyno);
                setSummary("");
              } else {
                setSelected(weeklyDate);
                setUploadedFiles(json.filelist);
                setSelectedFileList([]);
                setIsRegMode(false);
                setWeeklyNo(json.weeklyno);
                setSummary(json.summary);

              }
          }).catch(error => {
            console.error('Error:', error)
          });
       }

    const onSubmit = async(event) => { 
        event.preventDefault(); 
        let done = true;
   
          const userSession = getUserSession();
          // 첨부파일 전송 
          for (const selectedFile of selectedFileList) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('path', "weekly");
                formData.append('fieldName', "file");
                formData.append('uid', userSession.uid);
                formData.append('filekey', filekey);
                console.log(selectedFile.name);
                const response = await fetch(
                            serverUrl,
                            {
                                method: 'POST',
                                body: formData,
                                mode: 'cors',
                            }
                        );
                const result = await response.json();
                if(result.code !== "0000"){
                    done = false;
                    alert(result.msg);
                    break;
                }
            } catch(error) {
                done = false;
                console.error('Error:', error);
                break;
            }              
          } // for off
          console.log("done" , done);
          if(done){
            try { 
            const formData = new FormData();
            formData.append('uid', userSession.uid);
            formData.append('action', 'save');
            formData.append('weeklydate', selected);
            formData.append('filekey', filekey);
            formData.append('summary', summary);
            
            fetch('https://work.wekoinonia.org/json/weekly.php', {
              method: 'POST',
              body: formData,
              mode: 'cors',
              //headers: new Headers({
                //'Content-Type': 'application/json'
              //  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
              }).then((res) => { return res.json(); //Promise 반환
              }).then((json) => {
                  console.log(json); // 서버에서 주는 json데이터가 출력 됨
                  alert(json.msg);
                  fetchWeeklyInfo(selected); // 재조회
              }).catch(error => {
                console.error('Error:', error)
              });
            } catch(error) { 
            console.log(error)
            alert(error.code + "\n" + error.message);
            } 
        }
      }

      const onChange = (e) => {
        //setSelected(e.target.value);
        fetchWeeklyInfo(e.target.value);
      };

	const changeHandler = (event) => {
        //console.log(selectedFileList);
        //console.log(selectedFileList);
		setSelectedFileList(selectedFileList.concat(event.target.files[0]));
        //console.log(selectedFileList);
        event.target.value = null; // 초기화

		setIsSelected(true);
	};
	return(
        <section className="container">
        <h1> 주보  {isRegMode ? '등록' : '수정'} </h1>
        <MyInput>
            <div className="weekly-input">
                <Form onSubmit={onSubmit}  className="mb-4">
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                        발행 주일
                        </Form.Label>
                        <Col sm={10}>
                        <Form.Select size="lg"  onChange={onChange} value={selected}>
                            {sundayList.map((item) => (
                            <option value={item} key={item}>
                                {item}
                            </option>
                            ))}
                        </Form.Select>          
                        </Col>
                    </Form.Group> 
                    <Form.Group as={Row} className="mb-3" controlId="summary">
                        <Form.Label column sm={2}>
                        제목
                        </Form.Label>
                        <Col sm={10}>
                        <Form.Control type="text" placeholder="제목" value={summary} onChange={e => setSummary(e.target.value)  } required/>
                        </Col>
                    </Form.Group> 
                    <Row>
                        {uploadedFiles.map( uploadedFile => 
                            <WeeklyImage imageInfo={uploadedFile} weeklyNo={weeklyNo}/>
                        )}    
                    </Row>                    

                    <input type="hidden" name="filekey" value="{filekey}"/>
                    <Button variant="primary" type="submit" className="w-100">
                            저장
                    </Button>
                </Form> 
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>첨부파일 </Form.Label>
                    <Form.Control type="file" onChange={changeHandler} title=" " style={{display:'none'}}/>
                </Form.Group>
                {isSelected ? (
				<div>
                    <h5>선택된 파일  <Badge bg="secondary">{selectedFileList.length}</Badge>건 </h5>                    
                    <ListGroup>
                    {selectedFileList.map( (selectedFile) => (
                        <ListGroup.Item key={selectedFile.name}>
                        <i className={"bi bi-filetype-" + getExten(selectedFile.name) }></i>
                        {selectedFile.name},{selectedFile.size}
                        {/*selectedFile.lastModifiedDate.toLocaleDateString()*/}
                         </ListGroup.Item>
                    ))}
                   </ListGroup>
				</div>
			) : (
				<p>첨부하실 이미지를 선택하십시오 </p>
			)}            </div>
        </MyInput>
		</section>
	)
}

export default WeeklyForm;